import { Modal } from 'ant-design-vue'
import Vue from 'vue'
import Router from 'vue-router'

import { LoadingBar } from '@/common'
import store from '@/store'

const originalPush = Router.prototype.push
Router.prototype.push = function (location) {
  try {
    return originalPush.call(this, location).catch(err => err)
  } catch (e) {}
}

Vue.use(Router)

const beforeEach = async (to, from, next) => {
  LoadingBar.start()
  Modal.destroyAll()
  if (to.meta.permission) {
    next()
    return
  }
  const { perfect, token } = store.state
  if (token && perfect) {
    if (to.name === 'login') {
      next({ name: 'dashboard' })
      return
    } else {
      const appTest = /^\/app\//
      // 如果path 是/app/开头的就是应用的  菜单换过去
      if (appTest.test(to.path)) {
        store.commit('MODE', 'app')
      } else {
        store.commit('MODE', 'menu')
      }
      if (to.meta.id) {
        store.dispatch('onSelect', to.meta.id)
      }
      // 权限判断
      next()
      return
    }
  }
  if (!token && to.name !== 'login') {
    // 没登录
    next({ name: 'login' })
    return
  }
  if (token && !perfect && to.name !== 'perfect') {
    // 登录了没填信息
    next({ name: 'perfect' })
    return
  }
  // store.dispatch('onSelect', to.meta.id)
  next()
}

const afterEach = async () => {
  window.scrollTo(0, 0)
  LoadingBar.finish()
}

/**
 * 创建路由
 */
export default function createRouter(routes) {
  const finalRoutes = [
    {
      path: '/error-page',
      name: 'errorPage',
      component: () => import(/* webpackChunkName: "error-page" */ '@/views/ErrorPage'),
      meta: {
        permission: false,
      },
    },
    ...routes,
  ]
  const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: finalRoutes,
  })

  router.beforeEach(beforeEach)

  router.afterEach(afterEach)

  router.onError(() => {
    window.location.reload()
  })
  return router
}
