import './BjButton.less'

import { Button, Message, Modal } from 'ant-design-vue'

export default {
  name: 'BjButton',
  props: {
    ...Button.props,
    /**
     * 要跳转的路由
     */
    to: [String, Object],
    tooltips: String,
    modal: {
      type: [Boolean, String],
      default: false,
      validator: val => ['info', 'success', 'error', 'warning', 'confirm', true, false].includes(val),
    },
    modalType: {
      type: String,
      default: 'message',
      validator: val => ['message', 'modal'].includes(val),
    },
    title: { type: [String, Function, Object], default: '提示' },
    content: [String, Function, Object],
    closeEmitted: {
      type: Boolean,
      default: true,
    },
    modalProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) {
        return
      }
      if (this.to) {
        this.$router.push(this.to)
        return
      }
      if (this.modal) {
        const modalType = this.modal === true ? 'info' : this.modal
        if (this.modalType === 'modal' || this.modal === 'confirm') {
          const instance = Modal[modalType]({
            ...this.modalProps,
            title: this.title,
            content: this.content,
            onOk: close => {
              this.$emit('ok', close, instance)
              if (this.closeEmitted) {
                close()
                instance.destroy()
              }
            },
            onCancel: () => {
              this.$emit('cancel')
              instance.destroy()
            },
          })
        }
        if (this.modalType === 'message') {
          Message[modalType](this.content)
        }
        e.preventDefault()
        return
      }
      this.$emit('click', e)
    },
    renderBtn() {
      return (
        <a-button {...{ props: this.$props }} onClick={this.onClick}>
          {this.$slots.default}
        </a-button>
      )
    },
  },
  render() {
    if (this.tooltips || this.$slots.title) {
      return (
        <a-tooltip title={this.tooltips} placement='top'>
          {this.$slots.title ? <slot name='title' slot='title' /> : null}
          {this.renderBtn()}
        </a-tooltip>
      )
    }
    return this.renderBtn()
  },
}
