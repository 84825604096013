<script>
import { Message, Modal } from 'ant-design-vue'
/**
 * 参考： https://element.eleme.cn/#/zh-CN/component/button
 */
export default {
  name: 'BjLink',
  props: {
    /**
     * 按钮的类型，可选 `default` `primary` `danger` `success` `warning`
     */
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'primary', 'danger', 'success', 'warning'].includes(value),
    },
    /**
     * 是否显示下划线
     */
    underline: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否显示删除线
     */
    throughline: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否禁用
     */
    disabled: Boolean,
    /**
     * 是否是块状按钮
     */
    block: Boolean,
    /**
     * 按钮图标
     */
    icon: String,
    /**
     * 跳转的路由
     */
    to: [String, Object],
    /**
     * 是否替换当前路由
     */
    replace: Boolean,
    /**
     * 事件是否冒泡
     */
    bubble: {
      type: Boolean,
      default: false,
    },
    /**
     * 提示信息
     */
    tooltips: [String, Object, Array],
    modal: {
      type: [Boolean, String],
      default: false,
      validator: val => ['info', 'success', 'error', 'warning', 'confirm', true, false].includes(val),
    },
    modalType: {
      type: String,
      default: 'message',
      validator: val => ['message', 'modal'].includes(val),
    },
    title: { type: [String, Function, Object], default: '提示' },
    content: [String, Function, Object],
    closeEmitted: {
      type: Boolean,
      default: true,
    },
    modalProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    classes() {
      const classes = [this.$style.bjLink]
      if (this.type) {
        classes.push(this.$style[`bj-link--${this.type}`])
      }
      if (this.block) {
        classes.push(this.$style.isBlock)
      }
      if (this.disabled) {
        classes.push(this.$style.isDisabled)
      }
      if (this.underline) {
        classes.push(this.$style.isUnderline)
      }
      if (this.throughline) {
        classes.push(this.$style.isThroughline)
      }
      return classes
    },
  },
  methods: {
    onClick(e) {
      if (!this.bubble) {
        e.stopPropagation()
      }
      if (this.disabled) {
        return
      }
      if (this.to) {
        this.replace ? this.$router.replace(this.to) : this.$router.push(this.to)
        return
      }
      if (this.modal) {
        const modalType = this.modal === true ? 'info' : this.modal
        if (this.modalType === 'modal' || this.modal === 'confirm') {
          const instance = Modal[modalType]({
            ...this.modalProps,
            title: this.title,
            content: this.content,
            onOk: close => {
              this.$emit('ok', close, instance)
              if (this.closeEmitted) {
                close()
                instance.destroy()
              }
            },
            onCancel: () => {
              this.$emit('cancel')
              instance.destroy()
            },
          })
        }
        if (this.modalType === 'message') {
          Message[modalType](this.content)
        }
        e.preventDefault()
        return
      }
      this.$emit('click', e)
    },
    renderLink() {
      return (
        <a {...{ attrs: this.$attrs }} class={this.classes} onClick={this.onClick}>
          {this.icon ? <a-icon type={this.icon} /> : this.$slots.prefix && this.$slots.prefix}
          {this.$slots.default ? <span class={this.$style.bjLinkInner}>{this.$slots.default}</span> : null}
          {this.$slots.icon && this.$slots.icon}
        </a>
      )
    },
  },
  render() {
    if (this.tooltips) {
      return (
        <a-tooltip title={this.tooltips} placement='top'>
          {this.renderLink()}
        </a-tooltip>
      )
    }
    return this.renderLink()
  },
}
</script>

<style lang="less" module>
/* stylelint-disable property-no-unknown */
@typeMap: {
  primary: @primary-color;
  danger: @error-color;
  success: @success-color;
  warning: @warning-color;
};
/* stylelint-enable property-no-unknown */
.bj-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  & + &,
  & + :global(.btn-modal),
  :global(.btn-modal) + & {
    margin-left: 4px;
  }

  &.is-block {
    display: flex;
    align-items: center;

    .bj-link--inner {
      flex: 1;
    }
  }

  &.is-underline {
    &:hover::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      border-bottom: 1px solid @link-active-color;
      content: '';
    }
  }

  &.is-throughline {
    text-decoration: line-through;
  }

  :global(.anticon) {
    & + span {
      margin-left: 5px;
    }
  }

  &.bj-link--default {
    color: #000;

    &.is-disabled {
      color: @disabled-color;
      cursor: not-allowed;
    }

    i {
      color: #000 !important;
    }

    &:hover {
      color: @primary-color !important;

      i {
        color: @primary-color !important;
      }
    }

    &:after {
      border-color: @primary-color;
    }
  }

  each(@typeMap, {
    &.bj-link--@{key} {
      color: @value;

      &:hover {
        color: mix(@value, @white, 80%);
      }

      &:after {
        border-color: @value;
      }

      &.is-disabled {
        color: @disabled-color;
      }

      &.is-underline {
        &:hover::after{
          border-color: mix(@value, @white, 80%);
        }
      }
    }
  });
}
</style>
