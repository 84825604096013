<template>
  <span :class="$style.box" @click="$emit('click', $event)">
    <div :class="$style.block" v-if="!tooltip">
      <span :class="classNames"></span>
    </div>
    <a-tooltip v-else>
      <template slot="title"> {{ tooltip }} </template>
      <div :class="$style.block">
        <span :class="classNames"></span>
      </div>
    </a-tooltip>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BjTag',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'primary', 'danger', 'success', 'warning'].includes(value),
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    classNames() {
      const classNames = [this.$style.tag]
      if (!this.color) {
        classNames.push(this.$style[`tag-${this.type}`])
      }
      return classNames
    },
  },
}
</script>

<style lang="less" module>
/* stylelint-disable property-no-unknown */
@typeMap: {
  default: #bbb;
  primary: @primary-color;
  danger: @error-color;
  success: @success-color;
  warning: @warning-color;
};

.box {
  display: flex;
  align-items: center;
  height: 20px;
}

.tag {
  position: relative;
  top: -3px;
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 5px;
  each(@typeMap, {
    &-@{key}{
      background-color: @value;
    }
  });
}
</style>
