import http from '@/utils/http'

export class centerOrderService {
  // 获取订单列表
  getList(payload) {
    return http.post('/api/app_center/order/lists', payload, false, { mode: 'json' })
  }

  cannel(payload) {
    return http.post('/api/app_center/order/cancel', payload, false, { mode: 'json' })
  }

  getInfo(payload) {
    return http.post('/api/app_center/order/info', payload, false, { mode: 'json' })
  }
}
