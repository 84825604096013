import http from '@/utils/http'

export class appFormServce {
  // 表单信息
  getInfo(payload) {
    return http.post('/api/app/form/manage/form/info', payload, false, { mode: 'json' })
  }

  save(payload) {
    return http.post('/api/app/form/manage/form/save', payload, false, { mode: 'json' })
  }

  getList(payload) {
    return http.post('/api/app/form/manage/form/lists', payload, false, { mode: 'json' })
  }

  // 回收站数量
  recycleTotal(payload) {
    return http.post('/api/app/form/manage/form/recycle/total', payload, false, { mode: 'json' })
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/app/form/manage/form/recycle/lists', payload, false, { mode: 'json' })
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/app/form/manage/form/recycle/recover', payload, false, { mode: 'json' })
  }

  // 清空回收站
  recycleClear(payload) {
    return http.post('/api/app/form/manage/form/recycle/clean', payload, false, { mode: 'json' })
  }

  // 回收站彻底删除
  recycleDelete(payload) {
    return http.post('/api/app/form/manage/form/recycle/delete', payload, false, { mode: 'json' })
  }

  delete(payload) {
    return http.post('/api/app/form/manage/form/delete', payload, false, { mode: 'json' })
  }

  // 修改发布状态
  status(payload) {
    return http.post('/api/app/form/manage/form/status', payload, false, { mode: 'json' })
  }

  // 获取信息
  getSimple(payload) {
    return http.post('/api/app/form/manage/form/info/simple', payload, false, { mode: 'json' })
  }

  getListUser(payload) {
    return http.post('/api/app/form/manage/form/record/lists', payload, false, { mode: 'json' })
  }

  getSku(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/skus', payload, false, { mode: 'json' })
  }

  saveService(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/save', payload, false, { mode: 'json' })
  }

  getServe(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/info', payload, false, { mode: 'json' })
  }

  writeOff(payload) {
    return http.post('/api/app/form/manage/form/record/write_off', payload, false, { mode: 'json' })
  }

  unsubscribe(payload) {
    return http.post('/api/app/form/manage/form/record/unsubscribe', payload, false, { mode: 'json' })
  }
}
