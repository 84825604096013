const PENDING = 'PENDING'

export default {
  namespaced: true,
  state: {
    pending: false,
  },
  mutations: {
    [PENDING](state, payload) {
      state.pending = payload
    },
  },
  actions: {},
}
