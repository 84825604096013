<script>
/* eslint-disable */
import { Input } from 'ant-design-vue'

import BjValidationItem from './BjValidationItem'

export default {
  name: 'BjInput',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change.value',
  },
  /**
   * 参考： https://vue.ant.design/components/input-cn/#API
   */
  props: {
    ...BjValidationItem.props,
    ...Input.props,
    placeholder: {
      type: String,
      default: '请输入',
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
  render() {
    return (
      <BjValidationItem {...{ props: this.$props }}>
        <Input
          ref='input'
          {...{
            props: this.$props,
            attrs: this.$attrs,
            on: this.$listeners,
          }}
        >
          {Object.keys(this.$slots).map(name => (
            <template slot={name}>{this.$slots[name]}</template>
          ))}
        </Input>
      </BjValidationItem>
    )
  },
}
</script>
