import http from '@/utils/http'

export class userListService {
  // 获取用户列表
  getList(payload) {
    return http.post('/api/passport/culture/user/manage/list', payload, false, { mode: 'json' })
  }

  // 获取用户标签
  getTagList(payload) {
    return http.post('/api/passport/culture/user/manage/tag/list', payload, false, { mode: 'json' })
  }

  // 表头配置
  getConfig(payload) {
    return http.post('/api/passport/culture/user/manage/config', payload, false, { mode: 'json' })
  }

  // 表头配置
  saveConfig(payload) {
    return http.post('/api/passport/culture/user/manage/config/save', payload, false, { mode: 'json' })
  }

  // 批量修改状态
  saveStatus(payload) {
    return http.post('/api/passport/culture/user/manage/setstatus', payload, false, { mode: 'json' })
  }

  //设置标签
  saveTag(payload) {
    return http.post('/api/passport/culture/user/manage/settag', payload, false, { mode: 'json' })
  }

  // 用户详情
  getDetail(payload) {
    return http.post('/api/passport/culture/user/manage/detail', payload, false, { mode: 'json' })
  }

  // 删除标签
  deleteTag(payload) {
    return http.post('/api/passport/culture/user/manage/tag/delete', payload, false, { mode: 'json' })
  }

  // 编辑或者添加标签
  editTag(payload) {
    return http.post('/api/passport/culture/user/manage/tag/edit', payload, false, { mode: 'json' })
  }
}
