export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler, options) {
      if (element && event && handler) {
        element.addEventListener(event, handler, options)
      }
    }
  }
  return function (element, event, handler, options) {
    if (element && event && handler) {
      return element.attachEvent(`on${event}`, handler, options)
    }
  }
})()

export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        return element.removeEventListener(event, handler)
      }
    }
  }

  return function (element, event, handler) {
    if (element && event && handler) {
      return element.detachEvent(`on${event}`, handler)
    }
  }
})()

/**
 * 获取下拉的容器
 * @param {HTMLElement} trigger 触发元素
 * @param {string | string[] | boolean} wrapper 包裹元素
 */
export function getContainer(trigger, wrapper = ['ant-form', 'ant-drawer-body']) {
  if (!wrapper) {
    return document.body
  }
  if (trigger.tagName.toLowerCase() === 'body') {
    return trigger
  }
  const classNames = trigger.className.split(' ')
  const wrapperClassNames = Array.isArray(wrapper) ? wrapper : [wrapper]
  if (classNames.some(it => wrapperClassNames.includes(it))) {
    return trigger
  }
  return getContainer(trigger.parentNode, wrapper)
}

export const toggleClass = (element, className, force) => {
  const classList = element.className.split(' ')
  const index = classList.indexOf(className)
  if (force) {
    if (index === -1) {
      classList.push(className)
    }
  } else {
    if (index !== -1) {
      classList.splice(index, 1)
    }
  }
  element.className = classList.join(' ')
}
