import OSS from 'ali-oss'
import { Message } from 'ant-design-vue'
import axios from 'axios'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import router from '@/router'
import { systemService } from '@/service'
import { guid } from '@/utils'
// import { get, remove, set } from 'js-cookie'
import { BASE_URL } from '@/utils/variable'

const service = new systemService()

Vue.use(Vuex)

const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const SET_TOKEN = 'SET_TOKEN'
const SET_PREFECT = 'SET_PREFECT'
const UPLOAD_VISIBLE = 'UPLOAD_VISIBLE'
const COLLAPSED = 'COLLAPSED'
const SIDECHIRDSHOW = 'SIDECHIRDSHOW'
const ACTIVEMENU = 'ACTIVEMENU'
const EDITOR = 'EDITOR'
const COMMENT = 'COMMENT'
const USER = 'USER'
const MENU = 'MENE'
const PENDING = 'PENDING'
const ACTIVECHILD = 'ACTIVECHILD'
const EDITORBACKNAME = 'EDITORBACKNAME'
const FINISH = 'FINISH'
const CAR = 'CAR'
const UPlOADFILE = 'UPlOADFILE'
const UPLOADNUM = 'UPLOADNUM'
const REFRESHMATERIAL = 'REFRESHMATERIAL'
const MODE = 'MODE'
const REFRESHSHOPCAR = 'REFRESHSHOPCAR'
const OFFICIAL = 'official'

function getParentIds(id, data) {
  function dfs(data, id, parents) {
    for (var i = 0; i < data.length; i++) {
      var item = data[i]
      // 找到id则返回父级id
      if (item.identifier === id) return parents
      if (!item.children || !item.children.length) continue
      parents.push(item)
      if (dfs(item.children, id, parents).length) return parents
      parents.pop()
    }
    return []
  }
  return dfs(data, id, [])
}

export default function createStore(modules = {}, plugins = []) {
  return new Store({
    state: {
      token: null,
      refresh_token: null,
      expire: null,
      perfect: null,
      uploadVisible: false,
      uploadFile: [],
      uploadNum: 0,
      client: null,
      resumeclient: {}, // object {id}
      mode: 'menu', // 菜单模式  系统菜单menu  应用菜单app
      collapsed: true,
      sideChirdShow: true,
      activeMenu: [],
      activeChild: '', // 右边菜单id
      menu: {},
      editor: {}, // 编辑器初始信息
      editorBackName: '', // 编辑器返回页面 改成path  防一手带参数的页面返回
      // page_type	是	string		页面类型
      // index-首页
      // poc-文化点主页
      // template-模板页面
      // poc_id	是	string		文化点ID，page_type=poc时必传
      // id	是	string		模板页面ID，page_type=template时必传
      comment: {}, // 跳转评论携带信息
      user: {},
      pending: false,
      car: false,
      fileObj: {}, // file 对象
      refreshmaterial: '', // 随机触发新素材刷新
      refreshShopCar: '', // 随机触发购物车刷新
      official: {},
    },
    mutations: {
      [LOGIN](state, { token, refresh_token, attach, expire }) {
        state.token = token
        state.refresh_token = refresh_token
        state.perfect = attach.created_cultural_cloud
        state.expire = Number(Date.parse(new Date()).toString().substr(0, 10)) + Number(expire)
        state.user = attach
        if (!attach.created_cultural_cloud) {
          router.push({ name: 'perfect' })
          return
        }
        if (attach.created_cultural_cloud > 1) {
          router.push({ name: 'control' })
          return
        }
        router.push({ name: 'dashboard' })
      },
      refresh(state, { token, refresh_token, attach, expire }) {
        state.token = token
        state.refresh_token = refresh_token
        state.perfect = attach.created_cultural_cloud // 是否创建文化云 0-未创建 1-已创建, 2-包含多个文化云，需要选择
        state.expire = Number(Date.parse(new Date()).toString().substr(0, 10)) + Number(expire)
        state.user = attach
      },
      [FINISH](state, attach) {
        state.perfect = attach.created_cultural_cloud
        state.user = attach
        state.token = attach.token.token
        state.refresh_token = attach.token.refresh_token
        router.push({ name: 'dashboard' })
      },
      [LOGOUT](state) {
        state.token = null
        router.push({ name: 'login' })
        state.user = {}
        state.uploadFile = []
        state.uploadNum = 0
        state.refresh_token = null
        window.localStorage.clear()
      },
      [SET_TOKEN](state, payload) {
        state.token = payload
      },
      [SET_PREFECT](state, payload) {
        state.perfect = payload
      },
      [UPLOAD_VISIBLE](state, payload) {
        state.uploadVisible = payload
      },
      [COLLAPSED](state, payload) {
        state.collapsed = payload
      },
      [SIDECHIRDSHOW](state, payload) {
        state.sideChirdShow = payload
      },
      [ACTIVEMENU](state, payload) {
        state.activeMenu = payload
      },
      [MENU](state, payload) {
        state.menu = payload
      },
      [MODE](state, payload) {
        state.mode = payload
      },
      [EDITOR](state, payload) {
        state.editor = payload
      },
      [COMMENT](state, payload) {
        state.comment = payload
      },
      [USER](state, payload) {
        state.user = payload
      },
      [PENDING](state, payload) {
        state.pending = payload
      },
      [ACTIVECHILD](state, payload) {
        state.activeChild = payload
      },
      [EDITORBACKNAME](state, payload) {
        state.editorBackName = payload
      },
      [CAR](state, payload) {
        state.car = payload
      },
      [UPlOADFILE](state, payload) {
        state.uploadFile = payload
      },
      [UPLOADNUM](state, payload) {
        state.uploadNum = payload
      },
      [REFRESHMATERIAL](state, payload) {
        state.uploadNum = payload
      },
      [REFRESHSHOPCAR](state, payload) {
        state.refreshShopCar = payload
      },
      [OFFICIAL](state, payload) {
        state.official = payload
      },
    },
    actions: {
      async getCustomInfo({ commit }) {
        try {
          const { data } = await service.getOfficialInfo()
          commit('official', data)
          window.document.title = data.official_name + ' - 数字文化资产运营平台'
          Vue.official = data
          let $favicon = document.querySelector('link[rel="icon"]')
          $favicon.href = data.ico
        } catch (e) { }
      },
      async refreshToken({ state, commit }) {
        try {
          const { data } = await service.refreshToken({
            refresh_token: state.refresh_token,
          })
          commit('refresh', data)
        } catch (e) { }
      },
      onSelect({ state, dispatch, commit }, id) {
        if (state.menu[state.mode] && state.menu[state.mode].length) {
          let parent = getParentIds(id, state.menu[state.mode])
          if (parent.length) {
            commit('ACTIVEMENU', [parent[0].identifier])
            commit('ACTIVECHILD', id)
          } else {
            // 菜单 没找到这个页面
            commit('ACTIVEMENU', [])
            commit('ACTIVECHILD', null)
          }
        } else {
          setTimeout(() => {
            dispatch('onSelect', id)
          }, 100)
        }
      },
      async fetchMenu({ commit }) {
        try {
          const { data } = await service.fetchMenu()
          commit(MENU, data)
          commit(PENDING, true)
        } catch (e) { }
      },
      // 初始化oss
      async initOss({ state, dispatch }, payload) {
        const { data } = await service.ossToken({
          type: payload.type,
          group_id: payload.group_id,
          filename: payload.filename,
          file_size: payload.size,
        })
        state.refreshmaterial = Math.random()
        state.client = new OSS({
          region: data.region,
          bucket: data.bucket,
          accessKeyId: data.access_key_id,
          accessKeySecret: data.access_key_secret,
          stsToken: data.sts_token,
          callback: data.callback_option,
        })
        state.resumeclient[data.id] = state.client
        dispatch('multipartUpload', { res: data, ...payload })
      },
      // 大文件分片上传
      async multipartUpload({ state, commit }, payload) {
        try {
          commit('UPLOADNUM', state.uploadNum + 1)
          const data = {
            name: payload.filename,
            type: payload.type,
            size: payload.file.size,
            exception: true,
            progress: 0,
            id: payload.res.id,
            path: payload.res.cover,
            uid: payload.file.uid,
            file_id: payload.res.id,
          }
          state.uploadFile.unshift(data)
          state.fileObj[payload.res.id] = {
            file: payload.file,
            name: payload.res.oss_object,
          }
          state.name = payload.res.oss_object
          state.file = payload.file
          await state.client.multipartUpload(payload.res.oss_object, payload.file, {
            progress: (p, checkpoint) => {
              for (let index = 0; index < state.uploadFile.length; index++) {
                if (state.uploadFile[index].id === payload.res.id) {
                  state.uploadFile[index].progress = Number((p * 100).toFixed(2))
                  state.uploadFile[index].checkpoint = checkpoint
                }
              }
              if (p === 1) {
                commit('UPLOADNUM', state.uploadNum - 1)
              }
            },
            timeout: 20 * 1000, //设置超时时间
            mime: payload.file.type,
            // callback: payload.res.callback_option,
          })
        } catch (e) {
          state.uploadFile.map(item => {
            if (item.id === payload.res.id) {
              item.exception = 'warning'
            }
          })
        }
      },
      // 恢复上传
      async resumeUpload({ state }, payload) {
        if (!state.fileObj[payload.id]) {
          Message.error(
            '该素材可能受某些原因（网络、浏览器刷新或关闭等）影响，不能再次断点续传，请重新选择上传，已引用素材的地方请重新修改。'
          )
          state.uploadFile.map(item => {
            if (item.id === payload.id) {
              item.exception = false
            }
          })
          return
        }
        try {
          let tmp = state.fileObj[payload.id]
          let file = null
          state.uploadFile.map(item => {
            if (item.id === payload.id) {
              item.exception = true
              file = item
            }
          })
          state.uploadFile.map(item => {
            if (item.id === payload.id) {
              item.exception = true
            }
          })
          await state.client.multipartUpload(tmp.name, tmp.file, {
            progress: (p, checkpoint) => {
              for (let index = 0; index < state.uploadFile.length; index++) {
                if (state.uploadFile[index].id === payload.id) {
                  state.uploadFile[index].progress = p * 100
                  state.uploadFile[index].checkpoint = checkpoint
                }
              }
            },
            checkpoint: file.checkpoint,
          })
        } catch (e) {
          state.uploadFile.map(item => {
            if (item.id === payload.id) {
              item.exception = 'warning'
            }
          })
        }
      },
      // 素材中心弹窗图片上传弹窗top显示
      setFile({ state, dispatch }, payload) {
        const data = {
          name: payload.name,
          type: payload.type,
          size: payload.file.size,
          exception: true,
          progress: 0,
          id: guid(),
          uid: payload.file.uid,
          // path: payload.res.cover,
        }
        state.uploadFile.unshift(data)
        if (payload.type === 'image') {
          dispatch('uploadFile', { ...payload, id: data.id, size: data.size })
        } else {
          dispatch('uploadFile1', { ...payload, id: data.id, size: data.size })
        }
      },
      // 素材中心弹窗图片上传
      async uploadFile({ state, commit }, payload) {
        commit('UPLOADNUM', state.uploadNum + 1)
        let params = new FormData()
        params.append('material_image', payload.file)
        params.append('group_id', payload.group_id)
        params.append('file_size', payload.size)
        try {
          const data = await axios.post(BASE_URL + '/api/content/material/upload/image', params, payload.config)
          if (data.data.error === 'success') {
            state.uploadFile.map(item => {
              if (item.id === payload.id) {
                item.progress = 100
                item.exception = true
                item.path = data.data.data.path
                item.file_id = data.data.data.id // 后台id  之前生成的前端id不动
              }
            })
            state.refreshmaterial = Math.random()
            commit('UPLOADNUM', state.uploadNum - 1)
          } else {
            Message.error(
              data.data.error
            )
          }
        } catch (e) { }
      },
      async uploadFile1({ state, commit }, payload) {
        commit('UPLOADNUM', state.uploadNum + 1)
        let params = new FormData()
        params.append('file', payload.file)
        params.append('group_id', payload.group_id)
        params.append('file_size', payload.size)
        params.append('type', payload.type)
        try {
          const data = await axios.post(BASE_URL + '/api/content/material/upload/media', params, payload.config)
          console.log(data);
          if (data.data.error === 'success') {
            state.uploadFile.map(item => {
              if (item.id === payload.id) {
                item.progress = 100
                item.exception = true
                item.path = data.data.data.path
                item.file_id = data.data.data.id // 后台id  之前生成的前端id不动
              }
            })
            state.refreshmaterial = Math.random()
            commit('UPLOADNUM', state.uploadNum - 1)
          } else {
            Message.error(
              data.data.error
            )
          }
        } catch (e) {
        }
      },
    },
    getters: {
      headers({ token }) {
        return {
          Authorization: token,
          UnionCode: window.location.host.split('.').length === 4 ? window.location.host.split('.')[0] : '',
        }
      },
    },
    modules: {
      ...modules,
    },
    plugins: [
      createPersistedState({
        key: `BJ-${process.env.VUE_APP_NAME}`,
        // storage: {
        // getItem: get,
        // setItem: (key, value) => set(key, value, { expires: 7 }),
        // removeItem: remove,
        // storage: window.sessionStorage,
        // },
        reducer: ({
          token,
          refresh_token,
          expire,
          editorBackName,
          perfect,
          uploadFile,
          mode,
          uploadNum,
          editor,
          user,
          collapsed,
          sideChirdShow,
          official,
        }) => ({
          token,
          refresh_token,
          expire,
          perfect,
          mode,
          uploadFile,
          uploadNum,
          editor,
          user,
          collapsed,
          sideChirdShow,
          editorBackName,
          official,
        }),
      }),
      ...plugins,
    ],
  })
}
