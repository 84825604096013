import http from '@/utils/http'

export class commonService {
  // 判断是不设置作者信息
  authorCheck(payload) {
    return http.post('/api/passport/culture/account/author/check', payload)
  }

  // 绑定微信 and 修改
  authorBind(payload) {
    return http.post('/api/third_party/auth/bind', payload)
  }

  // 虚拟的那些个接口
  getDataList(payload) {
    return http.post('/api/statistics/tianfu/data', payload, false, { mode: 'json' })
  }
}
