import { Message } from 'ant-design-vue'
import axios from 'axios'
import qs from 'qs'

// import router from '@/router'
import store from '@/store'
import { BASE_URL } from '@/utils/variable'

let lastMsg = ''
// const errorMsg = {
//   401: '请求未授权',
//   403: '请求方法不允许',
//   405: '请求方法不允许',
// }

const instance = axios.create({
  baseURL: BASE_URL,
  // paramsSerializer(params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' })
  // },
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 60000,
})

instance.interceptors.request.use(async config => {
  if (
    store.state.expire < Number(Date.parse(new Date()).toString().substr(0, 10)) &&
    config.url !== '/api/passport/refresh_token' &&
    config.url !== '/api/passport/login' &&
    store.state.token
  ) {
    await store.dispatch('refreshToken')
  }
  config.headers = { ...store.getters.headers, ...(config.headers || {}) }
  if (config.method === 'post' && !config.mode) {
    config.data = qs.stringify(config.data, { arrayFormat: 'brackets' })
  }
  // mode: json
  if (config.method === 'post' && config.mode) {
    config.headers['Content-Type'] = 'application/json'
  }
  return config
})

instance.interceptors.response.use(({ data, ...args }) => {
  if (data.code !== 200) {
    return Promise.reject({ data, ...args })
  }
  return { data, ...args }
})

const showMsg = msg => {
  if (msg === lastMsg) {
    return
  }
  Message.error(msg, () => (lastMsg = ''))
  lastMsg = msg
}

const handleError = (res, reject) => {
  if (!res.data) {
    showMsg(String(res))
    return reject(res)
  }
  const { status, data } = res
  const { code } = data
  if (status === 200) {
    if (code === 401) {
      showMsg('登录已失效，请重新登录')
      store.commit('LOGOUT')
      return reject(data)
    } else if (code === 404) {
      showMsg('资源不存在')
      return reject({ success: false, msg: '', data: null, fail: true })
    } else if (code === 0) {
      showMsg((data && data.error) || '请求失败')
      return reject(data)
    } else if (code === 11) {
      Message.destroy()
      Message.error(data.error)
      return reject(data)
    } else {
      showMsg(data.error)
      return reject(data)
    }
  } else {
    let { data, msg } = res
    let message = msg || (data && data.msg) || '请求失败'
    if (message.toLowerCase() === 'network error') {
      message = '网络连接失败'
    }
    if (message.toLowerCase().includes('timeout')) {
      message = '请求超时'
    }
    showMsg(message)
    if (!data) {
      data = { success: false, msg: '', data: null, fail: true }
    }
    reject(data)
  }
}

function ajax(config, handle) {
  return new Promise((resolve, reject) => {
    instance
      .request(config)
      .then(({ data, ...args }) => {
        if (handle) {
          Message.success(data.msg)
        }
        if (typeof data === 'object' && data.toString() === '[object ArrayBuffer]') {
          resolve({ data, ...args })
        } else {
          resolve(data)
        }
      })
      .catch(res => handleError(res, reject, resolve))
  })
}

const http = {
  delete: (url, params = {}, handle = false, config = {}) =>
    ajax(
      {
        ...config,
        params,
        url,
        method: 'delete',
      },
      handle
    ),
  get: (url, params = {}, handle = false, config = {}) => ajax({ ...config, url, params }, handle),
  patch: (url, data = {}, handle = false, config = {}) =>
    ajax(
      {
        ...config,
        data,
        url,
        method: 'patch',
      },
      handle
    ),
  post: (url, data = {}, handle = false, config = {}) =>
    ajax(
      {
        ...config,
        data,
        url,
        method: 'post',
      },
      handle
    ),
  put: (url, data = {}, handle = false, config = {}) =>
    ajax(
      {
        ...config,
        data,
        url,
        method: 'put',
      },
      handle
    ),
}

export default http
