import http from '@/utils/http'

export class terminalManageService {
  // 获取所有管理数据
  getInfo(payload) {
    return http.post('/api/multi_terminal/terminal', payload)
  }

  // 获取小程序授权地址
  getUrl(payload) {
    return http.post('/api/third_party/wx/open/auth/url', payload)
  }

  // 微信小程序信息
  getWxDetail(payload) {
    return http.post('/api/multi_terminal/wx/mini_program/info', payload)
  }

  // 获取小程序版本信息
  getWxVersion(payload) {
    return http.post('/api/multi_terminal/wx/mini_program/version', payload)
  }

  // 小程序服务状态处理
  status(payload) {
    return http.post('/api/third_party/mini_program/wx/status', payload)
  }

  // 线上版本回退
  rollback(payload) {
    return http.post('/api/third_party/mini_program/wx/version/rollback', payload)
  }

  // 审核版本撤回审核
  revoke(payload) {
    return http.post('/api/third_party/mini_program/wx/version/audit/revoke', payload)
  }

  // 发布已审核通过的版本
  release(payload) {
    return http.post('/api/third_party/mini_program/wx/version/audit/release', payload)
  }

  // 重新提交审核
  recommit(payload) {
    return http.post('/api/third_party/mini_program/wx/version/audit/recommit', payload)
  }

  // 提交审核
  commit(payload) {
    return http.post('/api/third_party/mini_program/wx/version/audit/commit', payload)
  }

  // 获取小程序成员列表
  user(payload) {
    return http.post('/api/third_party/mini_program/wx/member/lists', payload)
  }

  //绑定小程序成员
  addUser(payload) {
    return http.post('/api/third_party/mini_program/wx/member/bind', payload)
  }

  //解除绑定小程序成员
  unbind(payload) {
    return http.post('/api/third_party/mini_program/wx/member/unbind', payload)
  }

  // 设置小程序体验版
  experience(payload) {
    return http.post('/api/third_party/mini_program/wx/version/experience', payload)
  }
}
