import http from "@/utils/http";

export class systemService {
  // 获取菜单
  fetchMenu(payload) {
    return http.post("/api/passport/culture/permission/menu", payload);
  }

  // 获取文化云类型下拉数据
  cloudType(payload) {
    return http.post("/api/passport/culture/cloud/type", payload);
  }

  // 获取文化云机构下拉
  organizationType(payload) {
    return http.post("/api/passport/culture/organization/type", payload);
  }

  // 创建文化云
  create(payload) {
    return http.post("/api/passport/culture/cloud/create", payload);
  }

  // 获取用户拥有文化云
  getUSerCloud(payload) {
    return http.post("/api/passport/login/cloud/list", payload);
  }

  // 重新获取token
  getToken(payload) {
    return http.post("/api/passport/login/second", payload, false, { mode: "json" });
  }

  /**
   * 根据父级地区ID获取下级地区
   * province-省, city-市, county-区县
   * parent_area_id
   */
  getArea(payload) {
    return http.post("/api/common/area", payload);
  }

  // 上传
  // 音频、视频素材上传OssToken
  ossToken(payload) {
    return http.post("/api/content/material/upload/media/token", payload, false, {
      mode: "json",
    });
  }

  // 图片上传
  update(payload) {
    return http.post("/api/content/material/upload/image", payload);
  }

  // 获取作者列表
  getAuthor(payload) {
    return http.post("/api/passport/culture/author/lists", payload);
  }

  // 删除作者
  deleteAuthor(payload) {
    return http.post("/api/passport/culture/author/delete", payload);
  }

  // 获取单条作者
  infoAuthor(payload) {
    return http.post("/api/passport/culture/author/info", payload);
  }

  // 独立上传接口
  upload(payload) {
    return http.post("/api/cultural/file/upload", payload);
  }

  // 获取账号列表
  accountList(payload) {
    return http.post("/api/passport/culture/account/lists", payload);
  }

  // 保存作者 新增
  createAuthor(payload) {
    return http.post("/api/passport/culture/author/create", payload);
  }

  // 保存作者 修改
  saveAuthor(payload) {
    return http.post("/api/passport/culture/author/modify", payload);
  }

  // 刷新token
  refreshToken(payload) {
    return http.post("/api/passport/refresh_token", payload);
  }

  // 获取首字母
  first_letter(payload) {
    return http.post("/api/common/util/convert/pinyin/first_letter", payload, false, {
      mode: "json",
    });
  }

  // 获取多端公共预览
  getPreview(payload) {
    return http.post("/api/multi_terminal/terminal/preview", payload, false, {
      mode: "json",
    });
  }

  // 多端下拉数据
  getTerminalDrop(payload) {
    return http.post("/api/multi_terminal/terminal/available", payload, false, {
      mode: "json",
    });
  }

  // base64 或 外链图片 转 oss
  imgToMaterial(payload) {
    return http.post("/api/content/material/upload/editor/image", payload, false, {
      mode: "json",
    });
  }

  // 获取合伙人的logo等等等。。。。
  getOfficialInfo(payload) {
    return http.post("/api/passport/official", payload, false, { mode: "json" });
  }
}
