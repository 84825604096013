import _ from 'lodash'
import { extend, localize } from 'vee-validate'
import {
  confirmed,
  email,
  integer,
  is,
  max,
  max_value,
  min,
  min_value,
  numeric,
  required,
} from 'vee-validate/dist/rules'

import zhCN from './zhCN'

const cellphone = value => /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)
const telphone = value => /^(\d{3,4}[-_]?)?\d{7,8}[-_]?(\d{3,4})?$/.test(value)
const bankNum = value => /^([1-9]{1})(\d{11,18})$/.test(value)

extend('required', required)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)
extend('confirmed', confirmed)
extend('integer', integer)
extend('is', is)
extend('email', email)

// 判断是否已经选择
extend('notinclude', {
  params: ['list'],
  validate(value, { list }) {
    if (typeof list === 'object') {
      list[0] = list[0].trim() // 去除首空格
    }
    return !list.includes(value)
  },
  message: '{_field_}已存在',
})

extend('int', { ...numeric, message: '{_field_}只能为整数' })

// 手机或者邮箱
extend('phoneEmail', {
  validate(value) {
    const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
    const regPhone = /^1[3456789]\d{9}$/
    return reg.test(value) || regPhone.test(value)
  },
  message: '请输入邮箱和手机号码',
})

// 非零整数
extend('numericNoZero', {
  validate(value) {
    const reg = /^\d+$/g
    return reg.test(value) && value - 0 > 0
  },
  message: '{_field_}只能为正整数',
})

// 正整数和零
extend('numericIncludeZero', {
  validate(value) {
    const reg = /^(0|\+?[1-9][0-9]*)$/
    return reg.test(value)
  },
  message: '{_field_}只能为正整数或者零',
})

// 判断重复
extend('includes', {
  message: '{_field_}已存在',
  validate(value, { values, field }) {
    return field ? values.filter(({ [field]: it }) => it === value).length <= 1 : !values.includes(value)
  },
  params: ['values', 'field'],
})

// 银行卡号长度
extend('bankNum', {
  message: '{_field_}格式不正确',
  validate(value) {
    if (!value) {
      return true
    }
    return bankNum(value)
  },
})

extend('telphone', {
  message: '{_field_}格式错误',
  validate: value => {
    if (!value) {
      return true
    }
    return telphone(value)
  },
})

extend('phone', {
  message: '{_field_}格式错误',
  validate: value => {
    if (!value) {
      return true
    }
    return cellphone(value) || telphone(value)
  },
})

extend('len', {
  message: '{_field_}不能超过{length}个字或字符',
  params: ['length'],
  validate: (value, { length }) => value.length <= length,
})

extend('zh_max', {
  message: '{_field_}不能超过{length}个字符',
  params: ['length'],
  validate: (value, { length }) => value.zhLength() <= length,
})

extend('max_word', {
  message: '{_field_}不能超过{length}个字',
  params: ['length'],
  validate: (value, { length }) => value.zhLength() <= length * 2,
})

extend('word', {
  message: '{_field_}只能为字母、数字和下划线',
  validate: value => {
    if (!value) {
      return true
    }
    return /^\w+$/gi.test(value)
  },
})

extend('word1', {
  message: '{_field_}只能为字母、数字、下划线和中划线',
  validate: value => {
    if (!value) {
      return true
    }
    return /^[\w|-]+$/gi.test(value)
  },
})

extend('url', {
  message: '{_field_}格式错误',
  validate: value => {
    if (!value) {
      return true
    }
    // eslint-disable-next-line no-useless-escape
    return /^(((ht|f)tps?):\/\/)?([\w\-]+(\.[\w\-]+)*\/)*[\w\-]+(\.[\w\-]+)*\/?(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?/.test(
      value
    )
  },
})

/**
 * 是否为数字（包含小数）
 */
extend('digits', {
  message: '{_field_}只能为数字',
  validate: value => {
    value = _.toNumber(value)
    const isValid = _.isFinite(value)
    return isValid
  },
})

extend('date', {
  message: '{_field_}格式错误',
  validate: value => {
    if (!value) {
      return true
    }
    return /^\d{4}-\d{2}-\d{2}$/.test(value)
  },
})

extend('idCard', {
  message: '{_field_}不合法',
  validate: value => {
    if (!value) {
      return true
    }
    const len = value.toString().length
    if (len === 15) {
      // 15位身份证的验证
      return /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/.test(value)
    }
    if (len === 18) {
      // 18位身份证的验证
      return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)
    }
    return false
  },
})

localize('zhCN', zhCN)
