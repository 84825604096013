<template>
  <a-config-provider :locale="locale">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
    }
  },
  created() {
    this.getCustomInfo()
  },
  methods: {
    ...mapActions(['getCustomInfo']),
  },
}
</script>
