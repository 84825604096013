import http from '@/utils/http'

export class platformService {
  // 平台信息
  getInfo(payload) {
    return http.post('/api/passport/culture/cloud/info', payload)
  }

  // 获取文化云类型下拉数据
  cloudType(payload) {
    return http.post('/api/passport/culture/cloud/type', payload)
  }

  // 设置名称和类型
  saveName(payload) {
    return http.post('/api/passport/culture/cloud/change', payload)
  }

  // 更换联系人
  saveInfo(payload) {
    return http.post('/api/passport/culture/cloud/contacts/change', payload)
  }

  // 更换管理员
  saveManage(payload) {
    return http.post('/api/passport/culture/cloud/master/change', payload)
  }

  // 获取文化云机构下拉
  organizationType(payload) {
    return http.post('/api/passport/culture/organization/type', payload)
  }

  // 保存认证
  saveAuthentication(payload) {
    return http.post('/api/passport/culture/cloud/certify/edit', payload, false, { mode: 'json' })
  }

  // 获取认证
  getAuthentication(payload) {
    return http.post('/api/passport/culture/cloud/certify/info', payload, false, { mode: 'json' })
  }

  /**
   * 根据父级地区ID获取下级地区
   * province-省, city-市, county-区县
   * parent_area_id
   */
  getArea(payload) {
    return http.post('/api/common/area', payload)
  }

  // 获取验证码
  getValidCode(payload) {
    return http.post(
      '/api/message_center/valid/code',
      {
        user_type: 'culture',
        ...payload,
      },
      false,
      { mode: 'json' }
    )
  }

  // 几个编辑器的获取和保存
  getAgreement(payload) {
    return http.post('/api/passport/culture/cloud/agreement/info', payload, false, { mode: 'json' })
  }

  saveAgreement(payload) {
    return http.post('/api/passport/culture/cloud/agreement/edit', payload, false, { mode: 'json' })
  }
}
