<script>
let span = 6
import { mapState } from 'vuex'
export default {
  name: 'BjBox',
  data() {
    return {
      show: false,
    }
  },
  props: {
    lose: {
      type: Number,
      default: 0,
    },
    // 宽高比图片的
    scale: {
      type: Number,
      default: 1.5,
    },
    dealHeight: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showMore() {
      this.show = !this.show
    },
    refresh() {
      this.$emit('refresh')
    },
    init() {
      this.$emit('init')
    },
  },
  computed: {
    ...mapState(['collapsed', 'sideChirdShow']),
  },
  mounted() {
    this.deal()
    window.addEventListener('resize', this.layout)
  },
  destroyed() {
    window.removeEventListener('resize', this.layout)
  },
  methods: {
    layout() {
      let menu = (this.sideChirdShow ? 160 : 0) + (this.collapsed ? 60 : 140)
      let current = document.documentElement.clientWidth - menu - this.lose
      if (current < 1100 && span !== 6) {
        this.$forceUpdate()
      } else if (current > 1099 && current < 1600 && span !== 4) {
        this.$forceUpdate()
      } else if (current > 1599 && current < 2000 && span !== 3) {
        this.$forceUpdate()
      } else {
        this.$forceUpdate()
      }
      this.deal()
    },
    deal() {
      if (!this.dealHeight) return
      if (document.getElementById('box')) {
        let img = document.getElementById('box').getElementsByTagName('img')
        let width = document.getElementById('item').offsetWidth - 20
        img.forEach(e => {
          e.style.height = width / this.scale + 'px'
        })
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.deal()
        }, 100)
      }
    },
  },
  render() {
    let menu = (this.sideChirdShow ? 160 : 0) + (this.collapsed ? 60 : 140)
    let current = document.documentElement.clientWidth - menu - this.lose
    if (current < 1100) {
      span = 6
    } else if (current > 1099 && current < 1600) {
      span = 4
    } else if (current > 1599 && current < 2000) {
      span = 3
    } else {
      span = 2
    }
    if (this.$slots.default) {
      return (
        <a-row id={'box'} type={'flex'} gutter={[20, 20]}>
          {Object.keys(this.$slots.default).map(index => (
            <a-col id={'item'} span={span}>
              {this.$slots.default[index]}
            </a-col>
          ))}
        </a-row>
      )
    }
  },
}
</script>
<style lang="less" module>
//
</style>
