import http from '@/utils/http'

export class centerStoreService {
  // 轮播
  getBanner(payload) {
    return http.post('/api/app_center/app/swiper', payload, false, { mode: 'json' })
  }

  // 列表
  getList(payload) {
    return http.post('/api/app_center/app/lists', payload, false, { mode: 'json' })
  }

  // 列表所有数据
  getAll(payload) {
    return http.post('/api/app_center/app/all', payload, false, { mode: 'json' })
  }

  // 列表分类
  getLeft(payload) {
    return http.post('/api/app_center/app/category', payload, false, { mode: 'json' })
  }

  // 获取应用详情
  getDetail(payload) {
    return http.post('/api/app_center/app/info', payload, false, { mode: 'json' })
  }

  // 添加购物车
  pushCar(payload) {
    return http.post('/api/app_center/cart/push', payload, false, { mode: 'json' })
  }

  // 用户应用列表
  userList(payload) {
    return http.post('/api/app_center/cloud_app/lists', payload, false, { mode: 'json' })
  }

  // 价格计算
  getPrice(payload) {
    return http.post('/api/app_center/cart/estimate/price', payload, false, { mode: 'json' })
  }
}
