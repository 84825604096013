import http from '@/utils/http'

export class sceneService {
  // 列表
  getList(payload) {
    return http.post('/api/trace_source/entrance/scene/lists', payload)
  }

  // 添加
  add(payload) {
    return http.post('/api/trace_source/entrance/scene/create', payload)
  }

  // 删除
  delete(payload) {
    return http.post('/api/trace_source/entrance/scene/delete', payload)
  }

  // 编辑
  edit(payload) {
    return http.post('/api/trace_source/entrance/scene/modify', payload)
  }
}
