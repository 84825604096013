<template>
  <a-switch :checked="value" @change="onChange" :size="size" />
</template>

<script>
export default {
  name: 'BjSwitch',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: [Number, String],
      required: true,
    },
    // default
    size: {
      type: String,
      default: 'small',
    },
    // checkedChildren: {
    //   type: String,
    //   default: '是',
    // },
    // unCheckedChildren: {
    //   type: String,
    //   default: '否',
    // },
  },
  computed: {
    value() {
      return this.checked === 1 || this.checked === '1'
    },
  },
  methods: {
    onChange(checked) {
      this.$emit('change', checked ? 1 : 0)
    },
  },
}
</script>
