import http from '@/utils/http'

export class appVenueSetService {
  // 获取开屏/文化主页
  getInfo(payload) {
    return http.post('/api/app/venue/manage/page/info', payload)
  }

  // 保存开屏/文化主页
  save(payload) {
    return http.post('/api/app/venue/manage/page/save', payload)
  }
}
