import http from '@/utils/http'

export class editorService {
  // 获取每种页面显示的不同组件
  getShow(payload) {
    return http.post('/api/multi_terminal/page/component/config', payload)
  }

  // 保存
  save(payload) {
    return http.post('/api/multi_terminal/page/save', payload)
  }

  // 获取页面
  info(payload) {
    return http.post('/api/multi_terminal/page/info', payload)
  }

  // 获取热门文化点列表
  hotPoc(payload) {
    return http.post('/api/multi_terminal/page/hot/poc', payload)
  }

  // 通知默认数据
  getNotice(payload) {
    return http.post('/api/app/notice/manage/notice/lists', payload, false, { mode: 'json' })
  }

  // 新闻默认数据
  getNews(payload) {
    return http.post('/api/multi_terminal/page/app/news/lists', payload, false, { mode: 'json' })
  }

  getVenue(payload) {
    return http.post('/api/multi_terminal/page/app/venue/lists', payload, false, { mode: 'json' })
  }
}
