import createRouter from './common'

const createProps = route => ({ query: route.query })

const routes = [
  {
    path: '/access',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
    meta: {
      permission: true,
    },
  },
  {
    path: '/perfect',
    name: 'perfect',
    component: () => import(/* webpackChunkName: "perfect" */ '@/views/perfect'),
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import(/* webpackChunkName: "protocol" */ '@/views/protocol'),
    meta: {
      permission: true,
    },
  },
  {
    path: '/control',
    name: 'control',
    component: () => import(/* webpackChunkName: "control" */ '@/views/control'),
    meta: {
      permission: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
    meta: {
      permission: true,
    },
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import(/* webpackChunkName: "editor" */ '@/views/editor/Home'),
  },
  {
    path: '/frontPreview',
    name: 'frontPreview',
    component: () => import(/* webpackChunkName: "frontPreview" */ '@/views/frontPreview/Home'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "entry" */ '@/views/Home'),
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "home" */ '@/views/overview/home'),
        props: createProps,
        meta: {
          permission: false,
          title: '平台首页',
          id: 'overview.dashboard',
        },
      },
      {
        path: 'content/material',
        name: 'contentMaterial',
        component: () => import(/* webpackChunkName: "contentMaterial" */ '@/views/content/material/Home'),
        props: createProps,
        meta: {
          title: '素材中心',
          id: 'content.material.manage',
        },
      },
      {
        path: 'resources/poc',
        name: 'resourcesPoint',
        component: () => import(/* webpackChunkName: "resourcesPoint" */ '@/views/resources/point/Home'),
        props: createProps,
        meta: {
          title: '文化点管理',
          id: 'resources.poc.manage',
        },
      },
      {
        path: 'resources/pocAdd/:id',
        name: 'resourcesPointAdd',
        component: () => import(/* webpackChunkName: "resourcesPointAdd" */ '@/views/resources/point/Add'),
        props: createProps,
        meta: {
          title: '新建文化点',
          id: 'resources.poc.manage',
          fNode: [
            {
              name: 'resourcesPoint',
              title: '文化点管理',
            },
          ],
        },
      },
      {
        path: 'resources/group',
        name: 'resourcesGroup',
        component: () => import(/* webpackChunkName: "resourcesGroup" */ '@/views/resources/group/Home'),
        props: createProps,
        meta: {
          title: '文化点分组',
          id: 'resources.poc.group',
        },
      },
      {
        path: 'resources/relation',
        name: 'resourcesRelation',
        component: () => import(/* webpackChunkName: "resourcesRelation" */ '@/views/resources/relation/Home'),
        props: createProps,
        meta: {
          title: '图谱关系',
          id: 'resources.poc.relation',
        },
      },
      {
        path: 'resources/relation/detail/:id',
        name: 'resourcesRelationDetail',
        component: () => import(/* webpackChunkName: "resourcesRelationDetail" */ '@/views/resources/relation/Detail'),
        props: createProps,
        meta: {
          title: '图谱关系',
          id: 'resources.poc.relation',
          fNode: [
            {
              name: 'resourcesRelation',
              title: '关系列表',
            },
          ],
        },
      },
      {
        path: 'resources/map/theme',
        name: 'resourcesMaptheme',
        component: () => import(/* webpackChunkName: "resourcesMaptheme" */ '@/views/resources/map/manage/theme'),
        props: createProps,
        meta: {
          title: '图谱主题',
          id: 'resources.poc.atlas.theme',
        },
      },
      {
        path: 'resources/map/theme/add',
        name: 'resourcesMapthemeAdd',
        component: () => import(/* webpackChunkName: "resourcesMapthemeAdd" */ '@/views/resources/map/manage/themeAdd'),
        props: createProps,
        meta: {
          title: '添加主题',
          id: 'resources.poc.atlas.theme',
          fNode: [
            {
              name: 'resourcesMaptheme',
              title: '图谱主题',
            },
          ],
        },
      },
      {
        path: 'resources/map/manage',
        name: 'resourcesMapManage',
        component: () => import(/* webpackChunkName: "resourcesMapManage" */ '@/views/resources/map/manage/Home'),
        props: createProps,
        meta: {
          title: '地图管理',
          id: 'resources.poc.map.manage',
        },
      },
      {
        path: 'resources/map/set',
        name: 'resourcesMapSet',
        component: () => import(/* webpackChunkName: "resourcesMapSet" */ '@/views/resources/map/set/Home'),
        props: createProps,
        meta: {
          title: '地图配置',
          id: 'resources.poc.map.config',
        },
      },

      {
        path: 'resources/comment',
        name: 'resourcesComment',
        component: () => import(/* webpackChunkName: "resourcesComment" */ '@/views/resources/comment/Homes'),
        props: createProps,
        meta: {
          title: '评论管理',
          id: 'resources.poc.comment',
        },
      },
      {
        path: 'resources/homeSetting',
        name: 'resourcesHomeSetting',
        component: () => import(/* webpackChunkName: "resourcesHomeSetting" */ '@/views/resources/homeSetting/Home'),
        props: createProps,
        meta: {
          title: '主页配置',
          id: 'resources.poc.page',
        },
      },
      // 文化溯源
      {
        path: 'source/scene',
        name: 'sourceScene',
        component: () => import(/* webpackChunkName: "sourceScene" */ '@/views/source/scene/Home'),
        props: createProps,
        meta: {
          title: '场景管理',
          id: 'trace_source.entrance.scene',
        },
      },
      {
        path: 'source/entrance',
        name: 'sourceEntrance',
        component: () => import(/* webpackChunkName: "sourceEntrance" */ '@/views/source/entrance/Home'),
        props: createProps,
        meta: {
          title: '入口管理',
          id: 'trace_source.entrance.manage',
        },
      },
      {
        path: 'content/manage',
        name: 'contentManage',
        component: () => import(/* webpackChunkName: "contentManage" */ '@/views/content/manage/Home'),
        props: createProps,
        meta: {
          title: '内容管理',
          id: 'content.manage',
        },
      },
      {
        path: 'content/manage/addImage/:id',
        name: 'contentManageAddImage',
        component: () => import(/* webpackChunkName: "contentManageAddImage" */ '@/views/content/manage/AddImage'),
        props: createProps,
        meta: {
          title: '新建图文',
          id: 'content.manage',
          fNode: [
            {
              name: 'contentManage',
              title: '内容管理',
            },
          ],
        },
      },
      {
        path: 'content/manage/AddVideo/:id',
        name: 'contentManageAddVideo',
        component: () => import(/* webpackChunkName: "contentManageAddVideo" */ '@/views/content/manage/AddVideo'),
        props: createProps,
        meta: {
          title: '新建视频',
          id: 'content.manage',
          fNode: [
            {
              name: 'contentManage',
              title: '内容管理',
            },
          ],
        },
      },
      {
        path: 'content/manage/AddAudio/:id',
        name: 'contentManageAddAudio',
        component: () => import(/* webpackChunkName: "contentManageAddAudio" */ '@/views/content/manage/AddAudio'),
        props: createProps,
        meta: {
          title: '新建音频',
          id: 'content.manage',
          fNode: [
            {
              name: 'contentManage',
              title: '内容管理',
            },
          ],
        },
      },
      {
        path: 'content/manage/AddAtlas/:id',
        name: 'contentManageAddAtlas',
        component: () => import(/* webpackChunkName: "contentManageAddAtlas" */ '@/views/content/manage/AddAtlas'),
        props: createProps,
        meta: {
          title: '新建图册',
          id: 'content.manage',
          fNode: [
            {
              name: 'contentManage',
              title: '内容管理',
            },
          ],
        },
      },
      {
        path: 'content/group',
        name: 'contentGroup',
        component: () => import(/* webpackChunkName: "contentGroup" */ '@/views/content/group/Home'),
        props: createProps,
        meta: {
          title: '内容分组',
          id: 'content.group',
        },
      },
      {
        path: 'content/comment',
        name: 'contentComment',
        component: () => import(/* webpackChunkName: "contentComment" */ '@/views/content/comment/Homes'),
        props: createProps,
        meta: {
          title: '评论管理',
          id: 'content.comment',
        },
      },
      {
        path: 'content/sync',
        name: 'contentSync',
        component: () => import(/* webpackChunkName: "contentSync" */ '@/views/content/sync/index'),
        props: createProps,
        meta: {
          title: '同步内容',
          id: 'content.support.sync',
        },
      },
      // 文化传播
      {
        path: 'spread/distribute',
        name: 'spreadDistribute',
        component: () => import(/* webpackChunkName: "spreadDistribute" */ '@/views/spread/distribute/Home'),
        props: createProps,
        meta: {
          title: '分发管理',
          id: 'spread.content.distribute',
        },
      },
      {
        path: 'spread/distributeAdd',
        name: 'spreadDistributeAdd',
        component: () => import(/* webpackChunkName: "spreadDistributeAdd" */ '@/views/spread/distribute/add'),
        props: createProps,
        meta: {
          title: '新建分发',
          id: 'spread.content.distribute',
        },
      },
      {
        path: 'spread/manage',
        name: 'spreadManage',
        component: () => import(/* webpackChunkName: "spreadManage" */ '@/views/spread/manage/Home'),
        props: createProps,
        meta: {
          title: '媒体管理',
          id: 'spread.media.manage',
        },
      },
      {
        path: 'spread/manage/add',
        name: 'spreadManageAdd',
        component: () => import(/* webpackChunkName: "spreadManageAdd" */ '@/views/spread/manage/add'),
        props: createProps,
        meta: {
          title: '授权新的媒体',
          id: 'spread.media.manage',
          fNode: [
            {
              name: 'spreadManage',
              title: '媒体管理',
            },
          ],
        },
      },
      {
        path: 'spread/manage/add/wx',
        name: 'spreadManageAddWx',
        component: () => import(/* webpackChunkName: "spreadManageAddWx" */ '@/views/spread/manage/wx'),
        props: createProps,
        meta: {
          title: '授权新的媒体',
          id: 'spread.media.manage',
          fNode: [
            {
              name: 'spreadManage',
              title: '媒体管理',
            },
            {
              name: 'spreadManageAdd',
              title: '授权新的媒体',
            },
          ],
        },
      },
      {
        path: 'spread/manage/detail',
        name: 'spreadManageDetail',
        component: () => import(/* webpackChunkName: "spreadManageDetail" */ '@/views/spread/manage/detail'),
        props: createProps,
        meta: {
          title: '微信公众号管理',
          id: 'spread.media.manage',
          fNode: [
            {
              name: 'spreadManage',
              title: '媒体管理',
            },
          ],
        },
      },
      {
        path: 'spread/record',
        name: 'spreadRecord',
        component: () => import(/* webpackChunkName: "spreadRecord" */ '@/views/spread/record/Home'),
        props: createProps,
        meta: {
          title: '分发记录',
          id: 'spread.distribute.record',
        },
      },
      // ------
      {
        path: 'terminal/manage',
        name: 'terminalManage',
        component: () => import(/* webpackChunkName: "terminalManage" */ '@/views/terminal/manage/Home'),
        props: createProps,
        meta: {
          title: '多端管理',
          id: 'terminal.multi_terminal.manage',
        },
      },
      {
        path: 'terminal/manage/wx',
        name: 'terminalManageWx',
        component: () => import(/* webpackChunkName: "terminalManage" */ '@/views/terminal/manage/wx'),
        props: createProps,
        meta: {
          title: '微信小程序',
          id: 'terminal.multi_terminal.manage',
          fNode: [
            {
              name: 'terminalManage',
              title: '多端管理',
            },
          ],
        },
      },
      {
        path: 'terminal/manage/wxAuthor',
        name: 'terminalManageWxAuthor',
        component: () => import(/* webpackChunkName: "terminalManageWxAuthor" */ '@/views/terminal/manage/wxAuthor'),
        props: createProps,
        meta: {
          title: '授权微信小程序',
          id: 'terminal.multi_terminal.manage',
          fNode: [
            {
              name: 'terminalManage',
              title: '多端管理',
            },
          ],
        },
      },
      {
        path: 'terminal/global',
        name: 'terminalGlobal',
        component: () => import(/* webpackChunkName: "terminalGlobal" */ '@/views/terminal/global/Home'),
        props: createProps,
        meta: {
          title: '全局配置 ',
          id: 'terminal.mobile.config',
        },
      },
      {
        path: 'terminal/screenSet',
        name: 'terminalScreenSet',
        component: () => import(/* webpackChunkName: "terminalAdSet" */ '@/views/terminal/screenSet/Home'),
        props: createProps,
        meta: {
          title: '开屏设置 ',
          id: 'terminal.mobile.screen',
        },
      },
      {
        path: 'terminal/template',
        name: 'terminalTemplate',
        component: () => import(/* webpackChunkName: "terminalTemplate" */ '@/views/terminal/template/Home'),
        props: createProps,
        meta: {
          title: '模板页面 ',
          id: 'terminal.mobile.template',
        },
      },
      {
        path: 'terminal/template/add',
        name: 'terminalTemplateAdd',
        component: () => import(/* webpackChunkName: "terminalTemplateAdd" */ '@/views/terminal/template/add'),
        props: createProps,
        meta: {
          title: '新建页面 ',
          id: 'terminal.mobile.template',
          fNode: [
            {
              name: 'terminalTemplate',
              title: '模板页面',
            },
          ],
        },
      },
      {
        path: 'terminal/modal',
        name: 'terminalModal',
        component: () => import(/* webpackChunkName: "terminalModal" */ '@/views/terminal/modal/Home'),
        props: createProps,
        meta: {
          title: '运营弹窗',
          id: 'terminal.mobile.operate_modal',
        },
      },
      {
        path: 'terminal/userCenter',
        name: 'terminalUserCenter',
        component: () => import(/* webpackChunkName: "terminalUserCenter" */ '@/views/terminal/userCenter/Home'),
        props: createProps,
        meta: {
          title: '个人中心 ',
          id: 'terminal.mobile.uc_config',
        },
      },
      // 系统
      {
        path: 'system/author',
        name: 'systemAuthor',
        component: () => import(/* webpackChunkName: "systemAuthor" */ '@/views/system/author/Home'),
        props: createProps,
        meta: {
          title: '作者管理',
          id: 'system.account.author',
        },
      },
      {
        path: 'system/authorAdd/:id',
        name: 'systemAuthorAdd',
        component: () => import(/* webpackChunkName: "systemAuthorAdd" */ '@/views/system/author/Add'),
        props: createProps,
        meta: {
          title: '添加作者',
          id: 'system.account.author',
        },
      },
      {
        path: 'system/account',
        name: 'systemAccount',
        component: () => import(/* webpackChunkName: "systemAccount" */ '@/views/system/account/Home'),
        props: createProps,
        meta: {
          title: '账号管理',
          id: 'system.permission.account',
        },
      },
      {
        path: 'system/platform',
        name: 'systemPlatform',
        component: () => import(/* webpackChunkName: "systemPlatform" */ '@/views/system/platform/Home'),
        props: createProps,
        meta: {
          title: '平台信息',
          id: 'system.account.platform',
        },
      },
      // 没认证添加的页面
      {
        path: 'system/platform/certificate',
        name: 'systemPlatformCertificate',
        component: () => import(/* webpackChunkName: "systemPlatformCertificate" */ '@/views/system/platform/Add'),
        props: createProps,
        meta: {
          title: '机构认证',
          id: 'system.account.platform',
          fNode: [
            {
              name: 'systemPlatform',
              title: '平台信息',
            },
          ],
        },
      },
      // 认证完了的信息页面
      {
        path: 'system/platform/certificateInfo',
        name: 'systemPlatformCertificateInfo',
        component: () => import(/* webpackChunkName: "systemPlatformCertificateInfo" */ '@/views/system/platform/Info'),
        props: createProps,
        meta: {
          title: '机构认证',
          id: 'system.account.platform',
          fNode: [
            {
              name: 'systemPlatform',
              title: '平台信息',
            },
          ],
        },
      },
      {
        path: 'system/platform/introduce',
        name: 'systemPlatformIntroduce',
        component: () => import(/* webpackChunkName: "systemPlatformIntroduce" */ '@/views/system/platform/introduce'),
        props: createProps,
        meta: {
          title: '平台介绍',
          id: 'system.account.platform',
          fNode: [
            {
              name: 'systemPlatform',
              title: '平台信息',
            },
          ],
        },
      },
      {
        path: 'system/platform/eula',
        name: 'systemPlatformEula',
        component: () => import(/* webpackChunkName: "systemPlatformEula" */ '@/views/system/platform/eula'),
        props: createProps,
        meta: {
          title: '用户协议',
          id: 'system.account.platform',
          fNode: [
            {
              name: 'systemPlatform',
              title: '平台信息',
            },
          ],
        },
      },
      {
        path: 'system/platform/privacy',
        name: 'systemPlatformPrivacy',
        component: () => import(/* webpackChunkName: "systemPlatformPrivacy" */ '@/views/system/platform/privacy'),
        props: createProps,
        meta: {
          title: '隐私保护说明',
          id: 'system.account.platform',
          fNode: [
            {
              name: 'systemPlatform',
              title: '平台信息',
            },
          ],
        },
      },
      {
        path: 'system/info',
        name: 'systemInfo',
        component: () => import(/* webpackChunkName: "systemInfo" */ '@/views/system/info/Home'),
        props: createProps,
        meta: {
          title: '账号信息',
          id: 'system.account.info',
        },
      },
      {
        path: 'system/role',
        name: 'systemRole',
        component: () => import(/* webpackChunkName: "systemRole" */ '@/views/system/role/Home'),
        props: createProps,
        meta: {
          title: '角色管理',
          id: 'system.permission.role',
        },
      },
      {
        path: 'system/permission',
        name: 'systemPermission',
        component: () => import(/* webpackChunkName: "systemPermission" */ '@/views/system/permission/Home'),
        props: createProps,
        meta: {
          title: '权限列表',
          id: 'system.permission.manage',
        },
      },
      {
        path: 'system/log',
        name: 'systemLog',
        component: () => import(/* webpackChunkName: "systemLog" */ '@/views/system/log/Home'),
        props: createProps,
        meta: {
          title: '操作日志',
          id: 'system.permission.op_log',
        },
      },
      // app router
      {
        path: 'app/notice/manage',
        name: 'noticeManage',
        component: () => import(/* webpackChunkName: "noticeManage" */ '@/views/app/notice/manage/Home'),
        props: createProps,
        meta: {
          title: '公告管理',
          id: 'app.notice.manage',
          // permission: true,
        },
      },
      {
        path: 'app/notice/add',
        name: 'noticeAdd',
        component: () => import(/* webpackChunkName: "noticeAdd" */ '@/views/app/notice/manage/add'),
        props: createProps,
        meta: {
          title: '发布公告',
          id: 'app.notice.add',
        },
      },
      {
        path: 'app/news/manage',
        name: 'newsManage',
        component: () => import(/* webpackChunkName: "newsManage" */ '@/views/app/news/manage/Home'),
        props: createProps,
        meta: {
          title: '资讯管理',
          id: 'app.news.manage',
        },
      },
      {
        path: 'app/news/group',
        name: 'newsGroup',
        component: () => import(/* webpackChunkName: "newsGroup" */ '@/views/app/news/group/Home'),
        props: createProps,
        meta: {
          title: '资讯分组',
          id: 'app.news.group',
        },
      },
      {
        path: 'app/news/add',
        name: 'newsAdd',
        component: () => import(/* webpackChunkName: "newsAdd" */ '@/views/app/news/manage/add'),
        props: createProps,
        meta: {
          title: '发布资讯',
          id: 'app.news.add',
        },
      },
      {
        path: 'app/community/posts',
        name: 'communityPosts',
        component: () => import(/* webpackChunkName: "communityPosts" */ '@/views/app/community/posts/Home'),
        props: createProps,
        meta: {
          title: '帖子管理',
          id: 'app.community.post',
        },
      },
      {
        path: 'app/community/channel',
        name: 'communityChannel',
        component: () => import(/* webpackChunkName: "communityChannel" */ '@/views/app/community/channel/Home'),
        props: createProps,
        meta: {
          title: '频道管理',
          id: 'app.community.channel',
        },
      },
      {
        path: 'app/community/comment',
        name: 'communityComment',
        component: () => import(/* webpackChunkName: "communityComment" */ '@/views/app/community/comment/Homes'),
        props: createProps,
        meta: {
          title: '评论管理',
          id: 'app.community.comment',
        },
      },
      {
        path: 'app/community/set',
        name: 'communitySet',
        component: () => import(/* webpackChunkName: "communitySet" */ '@/views/app/community/set/Home'),
        props: createProps,
        meta: {
          title: '社区设置',
          id: 'app.community.setting',
        },
      },
      // ---------文化场馆-------------
      {
        path: 'app/venue/manage',
        name: 'appVenueManage',
        component: () => import(/* webpackChunkName: "appVenueManage" */ '@/views/app/venue/manage/Home'),
        props: createProps,
        meta: {
          title: '场馆管理',
          id: 'app.venue.list',
        },
      },
      {
        path: 'app/venue/manage/add',
        name: 'appVenueManageAdd',
        component: () => import(/* webpackChunkName: "appVenueManageAdd" */ '@/views/app/venue/manage/add'),
        props: createProps,
        meta: {
          title: '场馆管理',
          id: 'app.venue.list',
        },
      },
      {
        path: 'app/venue/manage/service',
        name: 'appVenueManageService',
        component: () => import(/* webpackChunkName: "appVenueManageService" */ '@/views/app/venue/manage/service'),
        props: createProps,
        meta: {
          title: '场馆服务',
          id: 'app.venue.list',
          fNode: [
            {
              name: 'appVenueManage',
              title: '场馆管理',
            },
          ],
        },
      },
      {
        path: 'app/venue/manage/service/add',
        name: 'appVenueManageServiceAdd',
        component: () =>
          import(/* webpackChunkName: "appVenueManageServiceAdd" */ '@/views/app/venue/manage/service_add'),
        props: createProps,
        meta: {
          title: '添加预订',
          id: 'app.venue.list',
          fNode: [
            {
              name: 'appVenueManage',
              title: '场馆管理',
            },
            {
              name: 'appVenueManageService',
              title: '场馆服务',
            },
          ],
        },
      },
      {
        path: 'app/venue/group',
        name: 'appVenueGroup',
        component: () => import(/* webpackChunkName: "appVenueGroup" */ '@/views/app/venue/group/Home'),
        props: createProps,
        meta: {
          title: '场馆分组',
          id: 'app.venue.group',
        },
      },
      {
        path: 'app/venue/homeSet',
        name: 'appVenueHomeSet',
        component: () => import(/* webpackChunkName: "appVenueHomeSet" */ '@/views/app/venue/homeSet/Home'),
        props: createProps,
        meta: {
          title: '主页配置',
          id: 'app.venue.setting.home',
        },
      },
      {
        path: 'app/venue/map',
        name: 'appVenueMap',
        component: () => import(/* webpackChunkName: "appVenueMap" */ '@/views/app/venue/map/Home'),
        props: createProps,
        meta: {
          title: '地图配置',
          id: 'app.venue.setting.map',
        },
      },
      {
        path: 'app/venue/appointment',
        name: 'appVenueAppointment',
        component: () => import(/* webpackChunkName: "appVenueAppointment" */ '@/views/app/venue/appointment/Home'),
        props: createProps,
        meta: {
          title: '场馆预订',
          id: 'app.venue.appointment',
        },
      },
      {
        path: 'app/venue/comment',
        name: 'appVenueComment',
        component: () => import(/* webpackChunkName: "appVenueComment" */ '@/views/app/venue/comment/Home'),
        props: createProps,
        meta: {
          title: '评论管理',
          id: 'app.venue.comment',
        },
      },
      // 活动--------------------
      {
        path: 'app/activity/manage',
        name: 'appActivityManage',
        component: () => import(/* webpackChunkName: "appActivityManage" */ '@/views/app/activity/manage/Home'),
        props: createProps,
        meta: {
          title: '活动管理',
          id: 'app.activity.list',
        },
      },
      {
        path: 'app/activity/add',
        name: 'appActivityAdd',
        component: () => import(/* webpackChunkName: "appActivityAdd" */ '@/views/app/activity/add/Home'),
        props: createProps,
        meta: {
          title: '发布活动',
          id: 'app.activity.create',
        },
      },
      {
        path: 'app/activity/group',
        name: 'appActivityGroup',
        component: () => import(/* webpackChunkName: "appActivityGroup" */ '@/views/app/activity/group/Home'),
        props: createProps,
        meta: {
          title: '活动分组',
          id: 'app.activity.group',
        },
      },
      {
        path: 'app/activity/report',
        name: 'appActivityReport',
        component: () => import(/* webpackChunkName: "appActivityReport" */ '@/views/app/activity/manage/report'),
        props: createProps,
        meta: {
          title: '报名信息',
          id: 'app.activity.list',
          fNode: [
            {
              name: 'appActivityManage',
              title: '活动管理',
            },
          ],
        },
      },
      // 表单--------------------------------------------------------
      {
        path: 'app/form/manage',
        name: 'appFormManage',
        component: () => import(/* webpackChunkName: "appFormManage" */ '@/views/app/form/manage/Homes'),
        props: createProps,
        meta: {
          title: '表单管理',
          id: 'app.form.list',
        },
      },
      {
        path: 'app/form/detail',
        name: 'appFormDetail',
        component: () => import(/* webpackChunkName: "appFormDetail" */ '@/views/app/form/manage/details'),
        props: createProps,
        meta: {
          title: '表单详情',
          id: 'app.form.list',
          fNode: [
            {
              name: 'appFormManage',
              title: '表单管理',
            },
          ],
        },
      },
      {
        path: 'app/form/add',
        name: 'appFormAdd',
        component: () => import(/* webpackChunkName: "appFormAdd" */ '@/views/app/form/add/add'),
        props: createProps,
        meta: {
          title: '新建表单',
          id: 'app.form.create',
        },
      },
      // 用户
      {
        path: 'user/manage/list',
        name: 'userManageList',
        component: () => import(/* webpackChunkName: "userManageList" */ '@/views/user/manage/List/Home'),
        props: createProps,
        meta: {
          title: '全部用户',
          id: 'user.manage.list',
        },
      },
      {
        path: 'user/manage/list/detail/:id',
        name: 'userManageListDetail',
        component: () => import(/* webpackChunkName: "userManageListDetail" */ '@/views/user/manage/List/Detail'),
        props: createProps,
        meta: {
          title: '用户详情',
          id: 'user.manage.list',
          fNode: [
            {
              name: 'userManageList',
              title: '全部用户',
            },
          ],
        },
      },
      {
        path: 'user/manage/blackList',
        name: 'userManageBlackList',
        component: () => import(/* webpackChunkName: "userManageBlackList" */ '@/views/user/manage/blackList/Home'),
        props: createProps,
        meta: {
          title: '黑名单用户',
          id: 'user.manage.blacklist',
        },
      },
      {
        path: 'user/operate/tag',
        name: 'userOperateTag',
        component: () => import(/* webpackChunkName: "userOperateTag" */ '@/views/user/operate/tag/Home'),
        props: createProps,
        meta: {
          title: '标签管理',
          id: 'user.operate.tag',
        },
      },
      // ---------- 积分 ------------
      {
        path: 'integral/manage/overview',
        name: 'integralManageOverview',
        component: () =>
          import(/* webpackChunkName: "integralManageOverview" */ '@/views/integral/manage/overview/Home'),
        props: createProps,
        meta: {
          title: '积分概况',
          id: 'integral.account.dashboard',
        },
      },
      {
        path: 'integral/manage/detail',
        name: 'integralManageDetail',
        component: () => import(/* webpackChunkName: "integralManageDetail" */ '@/views/integral/manage/detail/Home'),
        props: createProps,
        meta: {
          title: '积分明细',
          id: 'integral.account.record',
        },
      },
      {
        path: 'integral/manage/rule',
        name: 'integralManageRule',
        component: () => import(/* webpackChunkName: "integralManageRule" */ '@/views/integral/manage/rule/Home'),
        props: createProps,
        meta: {
          title: '积分规则',
          id: 'integral.account.rule',
        },
      },
      // 应用中心
      {
        path: 'center/store/manage',
        name: 'centerStoreManage',
        component: () => import(/* webpackChunkName: "centerStoreManage" */ '@/views/center/store/Home'),
        props: createProps,
        meta: {
          title: '应用商店',
          id: 'app_center.market.app',
        },
      },
      {
        path: 'center/store/detail',
        name: 'centerStoreDetail',
        component: () => import(/* webpackChunkName: "centerStoreDetail" */ '@/views/center/store/detail'),
        props: createProps,
        meta: {
          title: '应用详情',
          id: 'app_center.market.app',
          fNode: [
            {
              name: 'centerStoreManage',
              title: '应用商店',
            },
          ],
        },
      },
      {
        path: 'center/service/manage',
        name: 'centerServiceManage',
        component: () => import(/* webpackChunkName: "centerServiceManage" */ '@/views/center/service/Home'),
        props: createProps,
        meta: {
          title: '服务商店',
          id: 'app_center.market.service',
        },
      },
      {
        path: 'center/service/detail',
        name: 'centerServiceDetail',
        component: () => import(/* webpackChunkName: "centerServiceDetail" */ '@/views/center/service/detail'),
        props: createProps,
        meta: {
          title: '服务详情',
          id: 'app_center.market.service',
          fNode: [
            {
              name: 'centerServiceManage',
              title: '服务商店',
            },
          ],
        },
      },
      {
        path: 'center/version/manage',
        name: 'centerVersionManage',
        component: () => import(/* webpackChunkName: "centerVersionManage" */ '@/views/center/version/Home'),
        props: createProps,
        meta: {
          title: '版本服务',
          id: 'app_center.market.version',
        },
      },
      {
        path: 'center/userApp/manage',
        name: 'centerUserAppManage',
        component: () => import(/* webpackChunkName: "centerUserAppManage" */ '@/views/center/userApp/Home'),
        props: createProps,
        meta: {
          title: '我的应用',
          id: 'app_center.manage.app',
        },
      },
      {
        path: 'center/userService/manage',
        name: 'centerUserServiceManage',
        component: () => import(/* webpackChunkName: "centerUserServiceManage" */ '@/views/center/userService/Home'),
        props: createProps,
        meta: {
          title: '我的服务',
          id: 'app_center.manage.service',
        },
      },
      {
        path: 'center/userOrder/manage',
        name: 'centerUserOrderManage',
        component: () => import(/* webpackChunkName: "centerUserOrderManage" */ '@/views/center/userOrder/Home'),
        props: createProps,
        meta: {
          title: '我的订单',
          id: 'app_center.manage.order',
        },
      },
      // -----------------------------公共页面----------------------------
      // 订单确认
      {
        path: 'common/order',
        name: 'commonOrder',
        component: () => import(/* webpackChunkName: "commonOrder" */ '@/views/common/order.vue'),
        props: createProps,
        meta: {
          title: '确认订单',
          id: 'common_pre_order',
        },
      },
      {
        path: 'common/cashier',
        name: 'commonCashier',
        component: () => import(/* webpackChunkName: "commonCashier" */ '@/views/common/cashier.vue'),
        props: createProps,
        meta: {
          title: '收银台',
          id: 'common_cashier',
          hideBread: true,
        },
      },
      // ---------- 虚拟  数据
      {
        path: 'data/overview',
        name: 'dataOverview',
        component: () => import(/* webpackChunkName: "dataOverview" */ '@/views/data/index.vue'),
        props: createProps,
        meta: {
          title: '数据概览',
          id: 'data.dashboard',
          // permission: true,
        },
      },
      {
        path: 'data/flow',
        name: 'dataFlow',
        component: () => import(/* webpackChunkName: "dataFlow" */ '@/views/data/flow.vue'),
        props: createProps,
        meta: {
          title: '流量分析',
          id: 'data.analysis.flow',
          // permission: true,
        },
      },
      {
        path: 'data/user',
        name: 'dataUser',
        component: () => import(/* webpackChunkName: "dataUser" */ '@/views/data/user.vue'),
        props: createProps,
        meta: {
          title: '用户分析',
          id: 'data.analysis.user',
          // permission: true,
        },
      },
      {
        path: 'data/resource',
        name: 'dataResource',
        component: () => import(/* webpackChunkName: "dataResource" */ '@/views/data/resource.vue'),
        props: createProps,
        meta: {
          title: '资源分析',
          id: 'data.analysis.resources',
          // permission: true,
        },
      },
      {
        path: 'data/content',
        name: 'dataContent',
        component: () => import(/* webpackChunkName: "dataContent" */ '@/views/data/content.vue'),
        props: createProps,
        meta: {
          title: '内容分析',
          id: 'data.analysis.content',
          // permission: true,
        },
      },
      {
        path: 'data/source',
        name: 'dataSource',
        component: () => import(/* webpackChunkName: "dataSource" */ '@/views/data/source.vue'),
        props: createProps,
        meta: {
          title: '溯源分析',
          id: 'data.analysis.trace_source',
          // permission: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
    meta: {
      permission: true,
    },
  },
]

export default createRouter(routes)
