import http from '@/utils/http'

export class groupService {
  // 获取分组列表
  list(payload) {
    return http.post('/api/cultural/resources/poc/group/lists', payload)
  }

  // 删除分组
  delete(payload) {
    return http.post('/api/cultural/resources/poc/group/delete', payload)
  }

  // 编辑分组
  edit(payload) {
    return http.post('/api/cultural/resources/poc/group/modify', payload)
  }

  // 添加分组
  add(payload) {
    return http.post('/api/cultural/resources/poc/group/create', payload)
  }

  // 获取平台预设分组
  defaultList(payload) {
    return http.post('/api/cultural/resources/poc/platform/group/lists', payload)
  }

  // 移动保存
  sort(payload) {
    return http.post('/api/cultural/resources/poc/group/sort', payload)
  }
}
