import http from '@/utils/http'

export class materialService {
  // 获取素材分组
  getGroup(payload) {
    return http.post('/api/content/material/group', payload)
  }

  // 获取单条
  getInfo(payload) {
    return http.post('/api/content/material/info', payload, false, { mode: 'json' })
  }

  // 添加分组
  addGroup(payload) {
    return http.post('/api/content/material/group/create', payload)
  }

  // 素材列表
  lists(payload) {
    return http.post('/api/content/material/lists', payload)
  }

  // 成功的素材列表 用于弹窗选择
  enableLists(payload) {
    return http.post('/api/cultural/choose/material', payload)
  }

  // 基础信息
  info(payload) {
    return http.post('/api/content/material/base/info', payload)
  }

  // 重命名分组
  rename(payload) {
    return http.post('/api/content/material/group/rename', payload)
  }

  // 删除分组
  deleteGroup(payload) {
    return http.post('/api/content/material/group/delete', payload)
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/content/material/recycle/lists', payload)
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/content/material/recycle/recover', payload)
  }

  // 回收站删除
  recycleDelete(payload) {
    return http.post('/api/content/material/recycle/delete', payload)
  }

  // 回收站清空
  recycleClear(payload) {
    return http.post('/api/content/material/recycle/clean', payload)
  }

  // 素材操作
  // 删除
  materialDelete(payload) {
    return http.post('/api/content/material/delete', payload)
  }

  // 编辑
  materialEdit(payload) {
    return http.post('/api/content/material/modify', payload)
  }

  // 移动
  materialMove(payload) {
    return http.post('/api/content/material/move/group', payload)
  }

  getoss(payload) {
    return http.post('/api/content/material/download/token', payload)
  }

  // 移动
  referList(payload) {
    return http.post('/api/content/material/refer/list', payload)
  }
}
