import http from '@/utils/http'

export class terminalScreenService {
  // 获取开屏/文化主页
  getInfo(payload) {
    return http.post('/api/multi_terminal/page/config/info', payload)
  }

  // 保存开屏/文化主页
  save(payload) {
    return http.post('/api/multi_terminal/page/config/save', payload)
  }
}
