<script>
import { Form } from 'ant-design-vue'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'BjForm',
  components: { ValidationObserver },
  inheritAttrs: false,
  /**
   * 参考： https://vue.ant.design/components/form-cn/#API
   */
  props: {
    ...Form.props,
    tag: {
      type: String,
      default: 'div',
    },
    // slim: {
    //   type: Boolean,
    //   default: true,
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: String,
    layout: {
      type: String,
      default: 'horizontal',
      validator: val => ['horizontal', 'vertical', 'inline', 'responsive'].includes(val),
    },
    labelCol: [Object, Number],
    wrapperCol: [Object, Number],
    formClass: String,
  },
  computed: {
    isReponsive() {
      return this.layout === 'responsive'
    },
    formLayout() {
      return this.isReponsive ? 'horizontal' : this.layout
    },
    realLabelCol() {
      if (!this.labelCol) {
        return
      }
      if (typeof this.labelCol === 'number') {
        return { span: this.labelCol }
      }
      return this.labelCol
    },
    realWrapperCol() {
      if (!this.wrapperCol) {
        return
      }
      if (typeof this.wrapperCol === 'number') {
        return { span: this.wrapperCol }
      }
      return this.wrapperCol
    },
  },
  methods: {
    onSubmit(valid) {
      if (!valid) {
        return
      }
      this.$emit('submit')
    },
    validate() {
      return this.$refs.observer.validate()
    },
    reset() {
      this.$refs.observer.reset()
    },
    handleSubmit(e) {
      e && e.preventDefault()
      this.validate().then(this.onSubmit)
    },
    renderForm(validation) {
      return (
        <a-form
          ref='form'
          {...{
            props: {
              ...this.$props,
              labelCol: this.realLabelCol,
              wrapperCol: this.realWrapperCol,
              layout: this.formLayout,
            },
            nativeOn: { submit: this.handleSubmit },
          }}
          class={[this.formClass, { [this.$style.responsive]: this.isReponsive }]}
        >
          {this.$slots.default || this.$scopedSlots.default(validation)}
        </a-form>
      )
    },
  },
  render() {
    return <ValidationObserver ref='observer' {...{ props: this.$props }} scopedSlots={{ default: this.renderForm }} />
  },
}
</script>

<style lang="less" module>
.responsive {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}
</style>
