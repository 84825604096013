import http from '@/utils/http'

export class entranceService {
  // 获取分组列表
  groupList(payload) {
    return http.post('/api/trace_source/entrance/group', payload)
  }

  // 添加分组
  groupAdd(payload) {
    return http.post('/api/trace_source/entrance/group/create', payload)
  }

  // 删除分组
  groupDelete(payload) {
    return http.post('/api/trace_source/entrance/group/delete', payload)
  }

  // 编辑分组
  groupEdit(payload) {
    return http.post('/api/trace_source/entrance/group/modify', payload)
  }

  // 列表
  getList(payload) {
    return http.post('/api/trace_source/entrance/lists', payload)
  }

  // 获取文化点分组
  getPoc(payload) {
    return http.post('/api/cultural/resources/poc/group/lists', payload)
  }

  // 获取文化内容分组
  getContent(payload) {
    return http.post('/api/content/group/lists', payload)
  }

  // 保存入口
  save(payload) {
    return http.post('/api/trace_source/entrance/save', payload)
  }

  // 删除入口
  delete(payload) {
    return http.post('/api/trace_source/entrance/delete', payload)
  }

  // 打包下载文化码
  download(payload) {
    return http.post('/api/trace_source/entrance/qr_code/pre_download', payload)
  }

  // 移动
  move(payload) {
    return http.post('/api/trace_source/entrance/group/move', payload)
  }
}
