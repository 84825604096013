import http from '@/utils/http'

export class terminalTemplateService {
  // 获取模板列表
  getList(payload) {
    return http.post('/api/multi_terminal/page/template/lists', payload, false, { mode: 'json' })
  }

  // 复制
  copy(payload) {
    return http.post('/api/multi_terminal/page/template/copy', payload, false, { mode: 'json' })
  }

  delete(payload) {
    return http.post('/api/multi_terminal/page/template/delete', payload, false, { mode: 'json' })
  }
}
