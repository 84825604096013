<script>
import { Select } from 'ant-design-vue'

import BjValidationItem from './BjValidationItem'
import { getContainer } from './utils'

export default {
  name: 'BjSelect',
  inject: {
    isDrawer: {
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  /**
   * 参考： https://vue.ant.design/components/select-cn/#API
   */
  props: {
    ...BjValidationItem.props,
    ...Select.props,
    // validationMode: {
    //   type: [String, Function],
    //   default: 'aggressive',
    // },
    keyField: { type: String, default: '' },
    valueField: { type: String, default: 'value' },
    labelField: { type: String, default: 'label' },
    /**
     * 应该是启用字段
     */
    disabledField: { type: String, default: 'disabled' },
    showDisabled: { type: Boolean, default: true },
    innerSearch: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否显示全部
     */
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    optionList() {
      if (!this.options) {
        return
      }
      const options = this.options.map(it => {
        const item = {
          ...it,
          key: it[this.keyField || this.valueField],
          label: it[this.labelField],
          value: it[this.valueField || this.keyField],
        }
        // item.disabled = this.showAll ? false : !it[this.disabledField]
        return item
      })
      // if (!this.showDisabled && !this.showAll) {
      //   return options.filter(it => !it.disabled)
      // }
      return options
    },
  },
  methods: {
    getContainer(trigger) {
      return this.getPopupContainer
        ? this.getPopupContainer(trigger)
        : getContainer(trigger, this.isDrawer && ['ant-form', 'ant-drawer-body'])
    },
    // eslint-disable-next-line vue/no-unused-properties
    blur() {
      this.$refs.select.blur()
    },
    // eslint-disable-next-line vue/no-unused-properties
    focus() {
      this.$refs.select.focus()
    },
    onFilter(keyword, option) {
      keyword = keyword.toLowerCase()
      if (!option.data.props) {
        const {
          propsData: { pinyinCode = '' },
          children: [node],
        } = option.componentOptions
        return (
          option.data.key.toLowerCase().includes(keyword) ||
          pinyinCode.toLowerCase().includes(keyword) ||
          node.text.toLowerCase().includes(keyword)
        )
      }
      const { [this.labelField]: label, [this.valueField]: value } = option.data.props
      return (label && label.toLowerCase().includes(keyword)) || (value && value.toLowerCase().includes(keyword))
    },
  },
  render() {
    const searchProps = this.innerSearch ? { showSearch: true, filterOption: this.filterOption || this.onFilter } : {}
    return (
      <BjValidationItem
        {...{
          props: { ...this.$props, mode: this.validationMode },
        }}
      >
        {this.$slots.prefix}
        <Select
          ref='select'
          class={this.$style.pullWidth}
          {...{
            props: {
              ...this.$props,
              ...searchProps,
              options: this.optionList,
              getPopupContainer: trigger => this.getContainer(trigger, this.isDrawer),
            },
            on: this.$listeners,
          }}
        >
          <i slot='suffixIcon' class='ri-arrow-down-s-line suffix' />
          {Object.keys(this.$slots).map(name => (
            <template slot={name}>{this.$slots[name]}</template>
          ))}
        </Select>
        {this.$slots.suffix}
      </BjValidationItem>
    )
  },
}
</script>
<style lang="less" module>
.pull-width {
  width: 100%;

  :global {
    .ant-select-arrow {
      top: 40%;
    }
  }
}
</style>
