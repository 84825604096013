<script>
let span = 6
import { mapState } from 'vuex'
export default {
  name: 'BjSearch',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showMore() {
      this.show = !this.show
    },
    refresh() {
      this.$emit('refresh')
    },
    init() {
      this.$emit('init')
    },
  },
  computed: {
    ...mapState(['collapsed', 'sideChirdShow']),
  },
  mounted() {
    window.onresize = () => {
      let menu = (this.sideChirdShow ? 160 : 0) + (this.collapsed ? 60 : 140)
      let current = document.documentElement.clientWidth - menu
      if (current < 1100 && span !== 12) {
        this.$forceUpdate()
      } else if (current > 1099 && current < 1300 && span !== 8) {
        this.$forceUpdate()
      } else if (current > 1299 && current < 2000 && span !== 6) {
        this.$forceUpdate()
      } else if (current > 1999 && span !== 4) {
        this.$forceUpdate()
      }
    }
  },
  render() {
    let menu = (this.sideChirdShow ? 160 : 0) + (this.collapsed ? 60 : 140)
    let current = document.documentElement.clientWidth - menu
    if (current < 1100) {
      span = 12
    } else if (current > 1099 && current < 1300) {
      span = 8
    } else if (current > 1299 && current < 2000) {
      span = 6
    } else {
      span = 4
    }
    let dom = []
    if (this.show) {
      dom = this.$slots.default
    } else {
      dom = this.$slots.default.slice(0, 24 / span)
    }
    return (
      <div class={`${this.$style.box} ${this.show ? this.$style.boxactive : ''}`}>
        <a-row type={'flex'} gutter={[20, 20]} class={this.$style.wrapper}>
          {Object.keys(dom).map(index => (
            <a-col class={this.$style.item} span={span}>
              {dom[index]}
            </a-col>
          ))}
        </a-row>
        <div class={this.$style.operation}>
          {this.$slots.default.length > 24 / span ? (
            <span class={`${this.$style.btn} ${this.show ? this.$style.rotate : ''}`}>
              <i class='ri-arrow-down-s-line' onClick={this.showMore} />
            </span>
          ) : null}
          <BjButton type='primary mr-10' onClick={this.refresh}>
            立即查询
          </BjButton>
          <BjButton class='btn-default' onClick={this.init}>
            重置条件
          </BjButton>
        </div>
      </div>
    )
  },
}
</script>

<style lang="less" module>
.box {
  display: flex;
  width: 100%;
  padding: 20px;
  background: #fff;
  // transition: height ease 5s;
  :global {
    .ant-input-group-addon {
      padding: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .wrapper {
    flex: 1;

    .item {
      height: 56px;
    }
  }

  .operation {
    width: 246px;
    line-height: 40px;
    text-align: right;
  }

  .btn {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #eee;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s;

    i {
      color: #000;
      font-size: 16px;
    }

    i:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>
