import _ from 'lodash'
import moment from 'moment'
import numeralize from 'numeral'
// import Vue from 'vue'
// import store from 'vuex'

// function checkPermission(el, binding) {
//   const { value } = binding
//   const has = hasPermission(value)
//   if (!has) {
//     el.parentNode && el.parentNode.removeChild(el)
//   }
// }

// function hasPermission(permission) {
//   return store.find(permission)
// }

// Vue.directive('permission', {
//   inserted: checkPermission,
//   update: checkPermission,
// })

// 生成随机唯一id
export function guid() {
  return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 格式化金额
 * @param {value} String 金额
 * @param {s} Number 小数点
 */
export function thousands(value, s = 0) {
  value = Number(value)
  if (Object.is(value, NaN)) {
    value = 0
  }
  const times = Math.pow(10, s)
  const result = Math.floor(value * times + 0.5) / times
  return result.toFixed(s).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * 格式化时间
 * @param {Date} date 时间
 * @param {string} format 格式
 */
export function locale(date, format = 'YYYY-MM-DD', defaults = '') {
  return date ? moment(date).format(format) : defaults
}

/**
 * 格式化数字
 * @param {number} value 数字
 * @param {string} format 格式
 * @param {string} defaults 如果格式化失败，显示的文本
 */
export function numeral(value, format = '0,0.0000', defaults = '') {
  if (value === '' || _.isNil(value)) {
    return defaults
  }
  const num = _.toNumber(value)
  if (_.isFinite(num)) {
    return numeralize(num).format(format)
  }
  return defaults
}

export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler, options) {
      if (element && event && handler) {
        element.addEventListener(event, handler, options)
      }
    }
  }
  return function (element, event, handler, options) {
    if (element && event && handler) {
      return element.attachEvent(`on${event}`, handler, options)
    }
  }
})()

/**
 * 映射字典为 Option
 * @param {object} target 字典
 */
export function mapToEnum(target) {
  return Object.entries(target).map(([code, label]) => ({
    code,
    label,
  }))
}

/**
 * 映射数组为枚举列表
 * @param {Array} target 数组
 * @param {boolean} startZero 是否从 `0` 开始
 */
export function mapArrayToEnum(target, startZero = false) {
  return target.map((label, index) => ({ label, code: startZero ? index : index + 1 }))
}

export function handleTreeData(
  data,
  { key = 'id', selectable, disableCheckbox, disabled, label, value, children } = {},
  reverse = true
) {
  return data.map(it => {
    let isDisabled = disabled && it[disabled]
    if (typeof isDisabled === 'number') {
      isDisabled = isDisabled === 1 ? true : false
    }
    if (typeof isDisabled === 'undefined') {
      isDisabled = false
    }
    const item = {
      ...it,
      key: it[key],
      selectable: (selectable && it[selectable]) || true,
      disableCheckbox: (disableCheckbox && it[disableCheckbox]) || false,
      disabled: reverse ? !isDisabled : isDisabled,
      title: it[label],
      value: it[value],
      children:
        it[children] &&
        handleTreeData(it[children], { selectable, disableCheckbox, disabled, label, value, children }, reverse),
    }
    if (!item.children.length) {
      delete item.children
    }
    return item
  })
}

/**
 * 去掉级联的空数组
 * @param {number} childName 自己名称
 */

export function filterEmptyChild(childName, treeData) {
  treeData.forEach(item => {
    if (item[childName] && item[childName].length) {
      filterEmptyChild(childName, item[childName])
    } else {
      delete item[childName]
    }
  })
}

export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        return element.removeEventListener(event, handler)
      }
    }
  }

  return function (element, event, handler) {
    if (element && event && handler) {
      return element.detachEvent(`on${event}`, handler)
    }
  }
})()
