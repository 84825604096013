import { Form } from 'ant-design-vue'
import { ValidationProvider } from 'vee-validate'

/**
 * 验证包装组件
 */
export default {
  name: 'BjValidationItem',
  components: { ValidationProvider },
  inheritAttrs: false,
  inject: {
    FormContext: { default: () => ({}) },
  },
  /**
   * 参考： https://logaretm.github.io/vee-validate/guide/validation-provider.html#props
   * https://vue.ant.design/components/form-cn/#API
   */
  props: {
    ...Form.Item.props,
    labelCol: { type: [Object, Number] },
    wrapperCol: { type: [Object, Number] },
    // ValidationProvider 的属性
    bails: {
      type: Boolean,
      default: true,
    },
    customMessages: {
      type: Object,
      default: () => ({}),
    },
    debounce: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: [String, Function],
      default: 'aggressive',
    },
    rules: [String, Object],
    skipIfEmpty: {
      type: Boolean,
      default: true,
    },
    // slim: {
    //   type: Boolean,
    //   default: true,
    // },
    tag: {
      type: String,
      default: 'div',
    },
    vid: String,
    // 重写 `FormItem` 的属性
    required: {
      type: Boolean,
      default: false,
    },
    colon: {
      type: Boolean,
      default: false,
    },
    // 扩展属性
    /**
     * 是否显示 `label`
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * 错误信息以文字提示的方式展示
     */
    tooltips: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'topLeft',
      validator: val =>
        [
          'top',
          'left',
          'right',
          'bottom',
          'topLeft',
          'topRight',
          'bottomLeft',
          'bottomRight',
          'leftTop',
          'leftBottom',
          'rightTop',
          'rightBottom',
        ].includes(val),
    },
  },
  computed: {
    /**
     * 是否必填，显示必填的 `*`
     */
    isRequired() {
      let required = false
      if (this.rules) {
        if (typeof this.rules === 'string') {
          required = this.rules.includes('required')
        } else {
          required = this.rules.required
        }
      }
      return this.required || required
    },
    /**
     * 是否显示冒号
     */
    displayColon() {
      return this.showLabel && this.colon
    },
    /**
     * 显示 `label` 的文本
     */
    displayLabel() {
      return this.showLabel ? this.label : ''
    },
    realLabelCol() {
      const { labelCol: contextLabelCol } = this.FormContext
      const { labelCol = contextLabelCol } = this
      if (!labelCol) {
        return
      }
      if (typeof labelCol === 'number') {
        return { span: labelCol }
      }
      return labelCol
    },
    realWrapperCol() {
      const { wrapperCol: contextWrapperCol } = this.FormContext
      const { wrapperCol = contextWrapperCol } = this
      if (!wrapperCol) {
        return
      }
      if (typeof wrapperCol === 'number') {
        return { span: wrapperCol }
      }
      return wrapperCol
    },
  },
  methods: {
    displayValidateStatus(pending, valid, untouched, validated) {
      if (this.validateStatus) {
        return this.validateStatus
      }
      if (untouched && !validated) {
        return ''
      }
      if (pending) {
        return 'validating'
      }
      if (valid) {
        return 'success'
      }
      return 'error'
    },
    errorMsg(errors) {
      if (this.tooltips) {
        return ''
      }
      if (this.help) {
        return this.help
      }
      return errors[0]
    },
    renderFormItem({ pending, errors, valid, untouched, validated }) {
      return (
        <a-form-item
          {...{
            props: {
              ...this.$props,
              labelCol: this.realLabelCol,
              wrapperCol: this.realWrapperCol,
              required: this.isRequired,
              colon: this.displayColon,
              label: this.displayLabel,
              help: this.errorMsg(errors),
              validateStatus: this.displayValidateStatus(pending, valid, untouched, validated),
            },
          }}
        >
          {Object.keys(this.$slots)
            .filter(name => name !== 'default')
            .forEach(name => (
              <template slot={name}>{this.$slots[name]}</template>
            ))}
          {this.$scopedSlots.help ? <template slot='help'>{this.$scopedSlots.help(errors[0])}</template> : null}
          {this.tooltips ? (
            <a-tooltip title={this.help || errors[0]} placement={this.placement}>
              <span>{this.$slots.default}</span>
            </a-tooltip>
          ) : (
            this.$slots.default
          )}
        </a-form-item>
      )
    },
  },
  render() {
    return (
      <ValidationProvider
        {...{ props: { ...this.$props, name: this.label } }}
        scopedSlots={{ default: this.renderFormItem }}
      />
    )
  },
}
