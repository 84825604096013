<template>
  <transition name="fade">
    <div v-show="show" :class="$style.fsLoadingBar" :style="outerStyles">
      <div :class="innerClasses" :style="styles" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
    failedColor: {
      type: String,
      default: 'error',
    },
    height: {
      type: Number,
      default: 2,
    },
    status: {
      type: String,
      validator: value => ['success', 'error'].includes(value),
      default: 'success',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerClasses() {
      return [
        this.$style.fsLoadingBarInner,
        {
          [this.$style.fsLoadingBarInnerColorPrimary]: this.color === 'primary' && this.status === 'success',
          [this.$style.fsLoadingBarInnerFailedColorError]: this.failedColor === 'error' && this.status === 'error',
        },
      ]
    },
    outerStyles() {
      return {
        height: `${this.height}px`,
      }
    },
    styles() {
      let style = {
        width: `${this.percent}%`,
        height: `${this.height}px`,
      }

      if (this.color !== 'primary' && this.status === 'success') {
        style.backgroundColor = this.color
      }

      if (this.failedColor !== 'error' && this.status === 'error') {
        style.backgroundColor = this.failedColor
      }

      return style
    },
  },
}
</script>

<style lang="less" module>
.fs-loading-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5000;
  width: 100%;

  &-inner {
    transition: width 0.2s linear;

    &-color-primary {
      background-color: @primary-color;
    }

    &-failed-color-error {
      background-color: @error-color;
    }
  }
}
</style>
