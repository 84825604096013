import http from '@/utils/http'

export class relationService {
  // 获取文化点列表
  list(payload) {
    return http.post('/api/cultural/resources/poc/relation/poc/search', payload)
  }

  // 获取文化点关系树
  tree(payload) {
    return http.post('/api/cultural/resources/poc/relation/tree', payload)
  }

  // 新增
  add(payload) {
    return http.post('/api/cultural/resources/poc/relation/create', payload, false, { mode: 'json' })
  }

  // 详情
  detail(payload) {
    return http.post('/api/cultural/resources/poc/relation', payload)
  }

  // 删除文化点关系
  delete(payload) {
    return http.post('/api/cultural/resources/poc/relation/delete', payload)
  }

  // 编辑信息
  info(payload) {
    return http.post('/api/cultural/resources/poc/relation/info', payload)
  }

  // 编辑
  edit(payload) {
    return http.post('/api/cultural/resources/poc/relation/modify', payload, false, { mode: 'json' })
  }
}
