import http from '@/utils/http'

export class centerService {
  // 购物车列表(我的订购)
  getList(payload) {
    return http.post('/api/app_center/cart/lists', payload, false, { mode: 'json' })
  }

  // 预下单
  getPre(payload) {
    return http.post('/api/app_center/order/pre', payload, false, { mode: 'json' })
  }

  // 创建订单
  create(payload) {
    return http.post('/api/app_center/order/create', payload, false, { mode: 'json' })
  }

  // 收银台订单信息
  cashier(payload) {
    return http.post('/api/finance/cashier', payload, false, { mode: 'json' })
  }

  // 支付统一下单
  unified(payload) {
    return http.post('/api/finance/payment/order/unified', payload, false, { mode: 'json' })
  }

  // 删除购物车
  deleteCar(payload) {
    return http.post('/api/app_center/cart/delete', payload, false, { mode: 'json' })
  }

  checkPay(payload) {
    return http.post('/api/finance/payment/check', payload, false, { mode: 'json' })
  }
}
