import http from '@/utils/http'

export class centerVersionService {
  // 获取版本列表
  getList(payload) {
    return http.post('/api/app_center/version/lists', payload, false, { mode: 'json' })
  }

  getVersionPrice(payload) {
    return http.post('/api/app_center/version/subscribe/price', payload, false, { mode: 'json' })
  }

  // 添加购物车
  pushCar(payload) {
    return http.post('/api/app_center/cart/push', payload, false, { mode: 'json' })
  }
}
