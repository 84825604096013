import http from '@/utils/http'

export class appActivityManage {
  // 获取表单
  getForm(payload) {
    return http.post('/api/app/form/manage/form/available/lists', payload, false, { mode: 'json' })
  }

  // 获取分组
  getGroup(payload) {
    return http.post('/api/app/activity/manage/group/lists', payload, false, { mode: 'json' })
  }

  // 添加
  onAdd(payload) {
    return http.post('/api/app/activity/manage/activities/create', payload, false, { mode: 'json' })
  }

  // 编辑
  onEdit(payload) {
    return http.post('/api/app/activity/manage/activities/modify', payload, false, { mode: 'json' })
  }

  // 详情
  getInfo(payload) {
    return http.post('/api/app/activity/manage/activities/info', payload, false, { mode: 'json' })
  }

  // 列表
  getList(payload) {
    return http.post('/api/app/activity/manage/activities/lists', payload, false, { mode: 'json' })
  }

  // 回收站数量
  getTotal(payload) {
    return http.post('/api/app/activity/manage/activities/recycle/total', payload, false, { mode: 'json' })
  }

  delete(payload) {
    return http.post('/api/app/activity/manage/activities/delete', payload, false, { mode: 'json' })
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/app/activity/manage/activities/recycle/lists', payload, false, { mode: 'json' })
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/app/activity/manage/activities/recycle/recover', payload, false, { mode: 'json' })
  }

  // 清空回收站
  recycleClear(payload) {
    return http.post('/api/app/activity/manage/activities/recycle/clean', payload, false, { mode: 'json' })
  }

  // 回收站彻底删除
  recycleDelete(payload) {
    return http.post('/api/app/activity/manage/activities/recycle/delete', payload, false, { mode: 'json' })
  }

  // 报名信息
  getInfoSimple(payload) {
    return http.post('/api/app/activity/manage/activities/info/simple', payload, false, { mode: 'json' })
  }

  // 表单列表
  getListUser(payload) {
    return http.post('/api/app/form/manage/form/record/lists', payload, false, { mode: 'json' })
  }

  // 获取信息
  getSimple(payload) {
    return http.post('/api/app/form/manage/form/info/simple', payload, false, { mode: 'json' })
  }

  // 删除登记记录
  deleteFormRecord(payload) {
    return http.post('/api/app/form/manage/form/record/delete', payload, false, { mode: 'json' })
  }

  writeOff(payload) {
    return http.post('/api/app/form/manage/form/record/write_off', payload, false, { mode: 'json' })
  }

  unsubscribe(payload) {
    return http.post('/api/app/form/manage/form/record/unsubscribe', payload, false, { mode: 'json' })
  }

  report(payload) {
    return http.post('/api/app/form/manage/form/record/pre_export', payload, false, { mode: 'json' })
  }
}
