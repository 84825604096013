import http from '@/utils/http'

export class terminalSettingService {
  // 获取全局配置
  getInfoGlobal(payload) {
    return http.post('/api/multi_terminal/terminal/config', payload)
  }

  // 保存全局配置
  saveInfoGlobal(payload) {
    return http.post('/api/multi_terminal/terminal/config/save', payload)
  }

  // 跳转链接-前端模块列表
  getModule(payload) {
    return http.post('/api/multi_terminal/module', payload)
  }

  // 获取个人中心配置
  getUser(payload) {
    return http.post('/api/multi_terminal/terminal/config/uc', payload)
  }

  // 保存个人中心配置
  saveUser(payload) {
    return http.post('/api/multi_terminal/terminal/config/uc/save', payload)
  }
}
