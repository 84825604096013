<template>
  <div :class="$style.preview" v-if="visible">
    <div :class="$style.view">
      <img v-if="data[index].type === 'image'" :style="rotate" :class="$style.img" :src="data[index].cover" alt="" />
      <video
        v-if="data[index].type === 'video'"
        controls="controls"
        autoplay
        :class="$style.img"
        :style="videoRotate"
        :src="data[index].cover"
      ></video>
      <audio
        v-if="data[index].type === 'audio'"
        controls="controls"
        autoplay
        :class="$style.img"
        :src="data[index].cover"
      >
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
    <div :class="$style.footer">
      <div :class="$style.page">
        <i @click="prev()" class="ri-arrow-left-s-line" :class="[$style.prev, index === 0 ? $style.disable : null]"></i>
      </div>
      <div :class="$style.btns">
        <i
          @click="rotateLeft()"
          class="ri-anticlockwise-2-line"
          :class="[data[index].type !== 'image' ? $style.disable : null]"
        ></i>
        <i
          @click="rotateRight()"
          class="ri-clockwise-2-line"
          :class="[data[index].type !== 'image' ? $style.disable : null]"
        ></i>
        <i @click="onScale()" :class="scale1 ? 'ri-fullscreen-exit-line' : 'ri-fullscreen-line'"></i>
        <i
          @click="narrow()"
          :class="[data[index].type !== 'image' ? $style.disable : null]"
          class="ri-zoom-out-line"
        ></i>
        <i
          @click="magnify()"
          :class="[data[index].type !== 'image' ? $style.disable : null]"
          class="ri-zoom-in-line"
        ></i>
      </div>
      <div :class="$style.page">
        <i
          @click="next()"
          class="ri-arrow-right-s-line"
          :class="[$style.next, index === data.length - 1 ? $style.disable : null]"
        ></i>
      </div>
    </div>
    <div :class="$style.control">
      <i @click="onClose()" class="ri-close-line"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BjPreview',
  data() {
    return {
      visible: false,
      data: [],
      current: null,
      index: 0,
      deg: 0,
      scale: 1,
      scale1: false,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.data.map((item, index) => {
          if (item.cover === this.current) {
            this.index = index
          }
        })
      } else {
        this.init()
      }
    },
  },
  computed: {
    rotate() {
      if (this.scale1) {
        return {
          transform: `rotate(${this.deg}deg) scale(${this.scale})`,
          'max-width': '100%',
          'max-height': '100%',
        }
      }
      return {
        transform: `rotate(${this.deg}deg) scale(${this.scale})`,
      }
    },
    videoRotate() {
      if (this.scale1) {
        return {
          width: '100%',
        }
      }
    },
  },
  methods: {
    onClose() {
      this.visible = false
      document.body.style.overflow = 'visible'
    },
    onScale() {
      this.scale = 1
      this.deg = 0
      this.scale1 = !this.scale1
    },
    rotateLeft() {
      this.deg -= 90
    },
    rotateRight() {
      this.deg += 90
    },
    // 放大
    magnify() {
      this.scale = this.scale + 0.2
    },
    // 缩小
    narrow() {
      if (this.scale < 0.3) {
        return
      }
      this.scale = this.scale - 0.2
    },
    prev() {
      if (this.index === 0) {
        return
      }
      this.index--
      this.deg = 0
    },
    next() {
      if (this.index === this.data.length - 1) {
        return
      }
      this.index++
      this.deg = 0
    },
    init() {
      this.data = []
      this.current = null
      this.index = 0
      this.deg = 0
    },
  },
}
</script>

<style lang="less" module>
.preview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.view {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100%);

  img {
    transition: 0.3s ease-out;
  }

  video {
    max-height: calc(100% - 160px);
  }
}

.footer {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;

  i {
    color: #fff;
    font-size: 22px;
  }

  .btns {
    width: 290px;
    height: 50px;
    background-color: rgba(92, 92, 92, 0.5);
    text-align: center;
    line-height: 50px;
    border-radius: 100px;
    margin: 0 20px;

    i {
      margin-right: 20px;
    }

    i:last-child {
      margin-right: 0;
    }
  }

  .page {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: rgba(92, 92, 92, 0.5);
    font-size: 22px;
    color: #fff;
    border-radius: 100%;
  }

  .disable {
    color: #bbb;
    cursor: not-allowed;
  }
}

.control {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: rgba(92, 92, 92, 0.5);
  font-size: 22px;
  color: #fff;
  text-align: center;
  border-radius: 100%;
}
</style>
