import http from '@/utils/http'

export class integralManageService {
  // 积分概况
  getInfo(payload) {
    return http.post('/api/integral/cloud/info', payload, false, { mode: 'json' })
  }

  // 明细
  getDetail(payload) {
    return http.post('/api/integral/cloud/detail', payload, false, { mode: 'json' })
  }

  // ----------规则------------

  // 任务列表
  getTask(payload) {
    return http.post('/api/integral/config/task', payload, false, { mode: 'json' })
  }

  // 编辑任务
  editTask(payload) {
    return http.post('/api/integral/config/task/edit', payload, false, { mode: 'json' })
  }

  // 基础规则
  getBase(payload) {
    return http.post('/api/integral/config/base', payload, false, { mode: 'json' })
  }

  // 回收列表
  getRecycle(payload) {
    return http.post('/api/integral/config/recycle', payload, false, { mode: 'json' })
  }

  // 积分规则 编辑
  editRecycle(payload) {
    return http.post('api/integral/config/recycle/edit', payload, false, { mode: 'json' })
  }

  // 编辑基础
  editBase(payload) {
    return http.post('/api/integral/config/base/edit', payload, false, { mode: 'json' })
  }
}
