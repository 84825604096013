import 'moment/locale/zh-cn'
import 'numeral/locales/chs'
import './utils/antd'
import './utils/validator'
import './less/global.less'
import './less/reset.less'
import './utils/directive'

import { Scene } from '@antv/l7'
import { GaodeMap } from '@antv/l7-maps'
import moment from 'moment'
import numeral from 'numeral'
import Vue from 'vue'
import { sync } from 'vuex-router-sync'

import { components } from '@/common'
import BjPreview from '@/common/BjPreview/index.js'

import App from './App'
import router from './router'
import store from './store'

sync(store, router)

components.forEach(component => {
  component.install = vue => {
    vue.component(component.name, component)
  }
})

for (const name in components) {
  Vue.use(components[name])
}

Vue.use(BjPreview)

new Scene({
  map: new GaodeMap({
    id: 'map',
    token: 'a126f5552c0c9d3a916de023b599e0bc',
    plugin: ['AMap.CitySearch', 'AMap.Geocoder', 'AMap.Autocomplete', 'AMap.PlaceSearch'],
  }),
})

Vue.config.productionTip = false
// Vue.config.silent = true
numeral.locale('chs')
moment.locale('zh-cn')

Vue.prototype.oss = 'https://minio.ycwly.com/cultural-cloud'
/* eslint-disable vue/require-name-property */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
