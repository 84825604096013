// import { Message } from 'ant-design-vue'
import Vue from 'vue'

import store from '@/store'

function checkPermission(binding) {
  const { value } = binding
  // return true
  if (store.state.menu.perms) {
    return !store.state.menu.perms.includes(value)
  }
  return false
}

/**
 * 权限指令
 *
 * 调用方式，在需要检验权限的按钮上添加 `v-permission:name`，`name` 是指对应页面该按钮的操作 Id
 */
Vue.directive('permission', {
  bind(el, binding, vnode) {
    setTimeout(() => {
      if (checkPermission(binding)) {
        if (el.tagName === 'BUTTON') {
          el.getElementsByTagName('span')[0].style.pointerEvents = 'none'
          vnode.child.disabled = true
          // el.style.cursor = 'not-allowed'
        }
        if (el.tagName === 'A') {
          vnode.child.disabled = true
          el.style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'SPAN') {
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          el.getElementsByTagName('i')[0].style.color = '#bbb'
          el.getElementsByTagName('i')[0].style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'I') {
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'DIV') {
          if (el.className.indexOf('ant-radio-group') !== -1) {
            // el.setAttribute('class','abc');
            // console.log(el.children)
            // console.log(el.getElementsByTagName('input'))
            vnode.child.disabled = true
            // el.className += ' ant-radio-disabled'
            // let arr = el.children
            // for (let index = 0; index < arr.length; index++) {
            //   console.log(arr[index])
            //   arr[index].className += ' ant-radio-wrapper-disabled'
            // }
            return
          }
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          if (el.getElementsByTagName('i').length) {
            el.getElementsByTagName('i')[0].style.color = '#bbb'
            el.getElementsByTagName('i')[0].style.cursor = 'not-allowed'
          }
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
      }
    })

    // console.log(binding)
    // console.log(vnode)
    // 样式处理
    // el.style.color = '#bbb'
    // vnode.child.disabled = true
    // el.disabled = 'disabled'
    // el.style.pointerEvents = 'none'
    // el.style.cursor = 'not-allowed'
    // el.addEventListener(
    //   'click',
    //   e => {
    //     e.stopPropagation()
    //   },
    //   {
    //     capture: true,
    //   }
    // )
  },
  update(el, binding, vnode) {
    setTimeout(() => {
      if (checkPermission(binding)) {
        if (el.tagName === 'BUTTON') {
          el.getElementsByTagName('span')[0].style.pointerEvents = 'none'
          vnode.child.disabled = true
          // el.style.cursor = 'not-allowed'
        }
        if (el.tagName === 'A') {
          vnode.child.disabled = true
          el.style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'SPAN') {
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          el.getElementsByTagName('i')[0].style.color = '#bbb'
          el.getElementsByTagName('i')[0].style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'I') {
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
        if (el.tagName === 'DIV') {
          if (el.className.indexOf('ant-radio-group') !== -1) {
            // el.setAttribute('class','abc');
            // console.log(el.children)
            // console.log(el.getElementsByTagName('input'))
            vnode.child.disabled = true
            // el.className += ' ant-radio-disabled'
            // let arr = el.children
            // for (let index = 0; index < arr.length; index++) {
            //   console.log(arr[index])
            //   arr[index].className += ' ant-radio-wrapper-disabled'
            // }
            return
          }
          el.style.color = '#bbb'
          el.style.cursor = 'not-allowed'
          if (el.getElementsByTagName('i').length) {
            el.getElementsByTagName('i')[0].style.color = '#bbb'
            el.getElementsByTagName('i')[0].style.cursor = 'not-allowed'
          }
          el.addEventListener(
            'click',
            e => {
              e.stopPropagation()
            },
            {
              capture: true,
            }
          )
        }
      }
    })
  },
})

/**
 * div 失焦事件
 * 用法 v-click-outside="xxx"
 * 绑定方法是不能写xxxx()
 */
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

/**
 * 是否拥有相应的权限
 * @param {string | string[]} permission 权限代码
 */
// export function hasPermission(permission) {
//   const plainMenuTree = store.getters['menu/plainMenuTree']
//   const [key] = store.state.menu.selectedKeys
//   const { operateList = [] } = plainMenuTree.find(({ id }) => key === id) || {}
//   permission = Array.isArray(permission) ? permission : [permission]
//   return operateList.some(
//     ({ domId, roleStatus, enableStatus }) => permission.includes(domId) && roleStatus && Boolean(enableStatus)
//   )
// }
