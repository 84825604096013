import http from '@/utils/http'

export class contentCommentService {
  // 回收站列表
  recycleList(payload) {
    return http.post('/api/content/comment/recycle/lists', payload)
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/content/comment/recycle/recover', payload)
  }

  // 回收站删除
  recycleDelete(payload) {
    return http.post('/api/content/comment/recycle', payload)
  }

  // 回收站清空
  recycleClear(payload) {
    return http.post('/api/content/comment/recycle/clean', payload)
  }

  // 回收站总数
  recycleTotal(payload) {
    return http.post('/api/content/comment/recycle/total', payload)
  }

  // 文化点列表  评论
  list(payload) {
    return http.post('/api/content/comment/content/lists', payload)
  }

  // 文化点列表  评论列表
  getDetail(payload) {
    return http.post('/api/content/comment/lists', payload)
  }

  // 设置内容评论状态
  setStatus(payload) {
    return http.post('/api/content/comment/setting/status', payload)
  }

  // 设置文化点评论阅读状态
  setRead(payload) {
    return http.post('/api/content/comment/read', payload)
  }

  // 评论星标置顶
  setStar(payload) {
    return http.post('/api/content/comment/star', payload)
  }

  // 评论通过
  examine(payload) {
    return http.post('/api/content/comment/examine', payload)
  }

  // 评论删除
  delete(payload) {
    return http.post('/api/content/comment/delete', payload)
  }

  // 垃圾
  spam(payload) {
    return http.post('/api/content/comment/spam', payload)
  }

  //人工智能
  getAudit(payload) {
    return http.post('/api/content/ugc/audit/config', payload)
  }

  //人工智能 保存
  saveAudit(payload) {
    return http.post('/api/content/ugc/audit/config/save', payload)
  }
}
