import http from '@/utils/http'

export class centerServiceService {
  // 获取服务列表
  getList(payload) {
    return http.post('/api/app_center/service/lists', payload, false, { mode: 'json' })
  }

  // 获取服务分类
  getType(payload) {
    return http.post('/api/app_center/service/category', payload, false, { mode: 'json' })
  }

  // 详情
  getDetail(payload) {
    return http.post('/api/app_center/service/info', payload, false, { mode: 'json' })
  }

  // 价格计算
  getPrice(payload) {
    return http.post('/api/app_center/cart/estimate/price', payload, false, { mode: 'json' })
  }

  // 添加购物车
  pushCar(payload) {
    return http.post('/api/app_center/cart/push', payload, false, { mode: 'json' })
  }

  getUserList(payload) {
    return http.post('/api/app_center/cloud_service/lists', payload, false, { mode: 'json' })
  }
}
