<template>
  <div :class="$style.ellipsis">
    <a-tooltip :title="title" placement="topLeft">
      <slot v-if="$slots.title" slot="title" name="slot" />
      <span>
        <slot>
          {{ title }}
        </slot>
      </span>
    </a-tooltip>
  </div>
</template>

<script>
/**
 * 省略过长字符
 */
export default {
  name: 'BjEllipsis',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" module>
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
