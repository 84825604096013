import http from '@/utils/http'

export class manageService {
  // 内容列表
  list(payload) {
    return http.post('/api/content/lists', payload)
  }

  // 分组
  groupList(payload) {
    return http.post('/api/content/group/lists', payload)
  }

  // 类型列表
  typeList(payload) {
    return http.post('/api/content/type/lists', payload)
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/content/recycle/lists', payload)
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/content/recycle/recover', payload)
  }

  // 回收站删除
  recycleDelete(payload) {
    return http.post('/api/content/recycle/delete', payload)
  }

  // 回收站清空
  recycleClear(payload) {
    return http.post('/api/content/recycle/clean', payload)
  }

  // 回收站总数
  recycleTotal(payload) {
    return http.post('/api/content/recycle/total', payload)
  }

  // 保存内容
  save(payload) {
    return http.post('/api/content/save', payload)
  }

  // 保存内容
  delete(payload) {
    return http.post('/api/content/delete', payload)
  }

  // 状态
  status(payload) {
    return http.post('/api/content/status', payload)
  }

  // info
  info(payload) {
    return http.post('/api/content/info', payload)
  }

  // 文化点关联内容
  relationPoc(payload) {
    return http.post('/api/cultural/resources/poc/content/relation', payload)
  }

  // 文化点取消关联内容
  relationPocDelete(payload) {
    return http.post('/api/cultural/resources/poc/content/relation/delete', payload)
  }

  // 获取作者列表
  getAuthor(payload) {
    return http.post('/api/passport/culture/author/lists', payload)
  }

  // 编辑器用热门文化点
  getEditorList(payload) {
    return http.post('/api/multi_terminal/page/content/lists', payload)
  }
}
