import http from '@/utils/http'

export class roleService {
  // 获取角色列表
  getListDefault(payload) {
    return http.post('/api/passport/culture/account/role/default', payload, false, { mode: 'json' })
  }

  getListUser(payload) {
    return http.post('/api/passport/culture/account/role/list', payload, false, { mode: 'json' })
  }

  // 获取角色权限树
  getTree(payload) {
    return http.post('/api/passport/culture/account/perms', payload, false, { mode: 'json' })
  }

  // 添加角色
  roleAdd(payload) {
    return http.post('/api/passport/culture/account/role/add', payload, false, { mode: 'json' })
  }

  // 删除角色
  roleDelete(payload) {
    return http.post('/api/passport/culture/account/role/delete', payload, false, { mode: 'json' })
  }

  // 编辑角色
  roleEdit(payload) {
    return http.post('/api/passport/culture/account/role/edit', payload, false, { mode: 'json' })
  }

  // 日志
  getLog(payload) {
    return http.post('/api/passport/culture/account/oplog', payload, false, { mode: 'json' })
  }

  // 获取模块
  getOption(payload) {
    return http.post('/api/passport/culture/account/oplog/op', payload, false, { mode: 'json' })
  }

  // 获取操作
  getModule(payload) {
    return http.post('/api/passport/culture/account/oplog/module', payload, false, { mode: 'json' })
  }

  // -  - - - -  -- 账号相关 -0 - - -  --

  // 账号删除
  manageDelete(payload) {
    return http.post('/api/passport/culture/account/manage/delete', payload, false, { mode: 'json' })
  }

  // 查询账号是否存在
  manageCheck(payload) {
    return http.post('/api/passport/culture/account/manage/check', payload, false, { mode: 'json' })
  }

  // 添加
  manageCreate(payload) {
    return http.post('/api/passport/culture/account/manage/create', payload, false, { mode: 'json' })
  }

  // 绑定已有账号
  manageAssociate(payload) {
    return http.post('/api/passport/culture/account/manage/associate', payload, false, { mode: 'json' })
  }

  // 编辑
  manageEdit(payload) {
    return http.post('/api/passport/culture/account/manage/edit', payload, false, { mode: 'json' })
  }

  // 角色账号列表
  roleUserList(payload) {
    return http.post('/api/passport/culture/account/role/accounts', payload, false, { mode: 'json' })
  }
}
