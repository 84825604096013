import http from '@/utils/http'

export class spreadManageService {
  // 列表
  getList(payload) {
    return http.post('/api/spread/media/lists', payload, false, { mode: 'json' })
  }

  // 添加列表
  getType(payload) {
    return http.post('/api/spread/media/type/lists', payload, false, { mode: 'json' })
  }

  // 获取已授权微信公众号列表
  getWxList(payload) {
    return http.post('/api/spread/media/wx/terminal/official/lists', payload, false, { mode: 'json' })
  }

  // 选择已授权公众号为媒体号
  selectOfficial(payload) {
    return http.post('/api/spread/media/wx/select/official', payload, false, { mode: 'json' })
  }

  // 获取媒体号
  getMediaInfo(payload) {
    return http.post('/api/spread/media/info', payload, false, { mode: 'json' })
  }

  // 获取媒体号分发列表
  getMedia(payload) {
    return http.post('/api/spread/media/distribute/record/lists', payload, false, { mode: 'json' })
  }

  contentTypeList(payload) {
    return http.post('/api/content/group/lists', payload)
  }

  // 获取作者列表
  getAuthor(payload) {
    return http.post('/api/passport/culture/author/lists', payload)
  }

  getDistributeList(payload) {
    return http.post('/api/spread/distribute/task/lists', payload)
  }

  //
  getSyncList(payload) {
    return http.post('/api/spread/distribute/task/lists', payload)
  }

  taskSyncList(payload) {
    return http.post('/api/spread/sync/content/task', payload)
  }

  availableSync(payload) {
    return http.post('/api/spread/media/available/select/lists', payload)
  }
}
