<template>
  <div :class="$style.card">
    <slot>
      {{ title }}
    </slot>
  </div>
</template>

<script>
/**
 * 省略过长字符
 */
export default {
  name: 'BjCard',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" module>
.card {
  padding: 12px;
  color: #434343;
  font-size: 12px;
  background-color: #fafafa;
  border-radius: 6px;
}
</style>
