import http from '@/utils/http'

export class systemInfoService {
  // 获取验证码
  getValidCode(payload) {
    return http.post(
      '/api/message_center/valid/code',
      {
        user_type: 'culture',
        ...payload,
      },
      false,
      { mode: 'json' }
    )
  }

  // 验证验证码是否正确
  checkValidCode(payload) {
    return http.post('/api/passport/account/check', payload)
  }

  // 获取作者信息
  getAuthor(payload) {
    return http.post('/api/passport/culture/account/author/info', payload)
  }

  // 保存作者信息
  saveAuthor(payload) {
    return http.post('/api/passport/culture/account/author/save', payload)
  }

  // 获取第三方绑定的状态 #platform_type wx
  getBind(payload) {
    return http.post('/api/third_party/auth/bind', payload)
  }

  // 获取账号信息
  getInfo(payload) {
    return http.post('/api/passport/culture/account/base', payload)
  }

  // 检查第三方账号绑定状态
  getBindCheck(payload) {
    return http.post('/api/third_party/auth/bind/check', payload)
  }

  // 保存作者信息
  saveBase(payload) {
    return http.post('/api/passport/culture/account/base/save', payload)
  }

  // 修改密码
  password(payload) {
    return http.post('/api/passport/culture/account/password', payload)
  }

  // 绑定账号
  bind(payload) {
    return http.post('/api/passport/culture/account/bind', payload)
  }

  // 修改账号
  change(payload) {
    return http.post('/api/passport/culture/account/change', payload)
  }
}
