import http from '@/utils/http'

export class resourceMapService {
  // 获取搜索列表
  getList(payload) {
    return http.post('/api/cultural/resources/poc/map/search', payload, false, { mode: 'json' })
  }

  // 详情
  getDetail(payload) {
    return http.post('/api/cultural/resources/poc/detail', payload, false, { mode: 'json' })
  }

  // 地图列表
  getMap(payload) {
    return http.post('/api/cultural/resources/poc/map/search/min', payload, false, { mode: 'json' })
  }

  // 地图配置
  getSet(payload) {
    return http.post('/api/cultural/resources/poc/map/config', payload, false, { mode: 'json' })
  }

  // 地图配置修改
  editSet(payload) {
    return http.post('/api/cultural/resources/poc/map/config/edit', payload, false, { mode: 'json' })
  }

  // 分组
  getGroup(payload) {
    return http.post('/api/cultural/resources/poc/group/lists', payload)
  }

  // 图谱
  getThemeList(payload) {
    return http.post('/api/cultural/resources/poc/theme/list', payload, false, { mode: 'json' })
  }

  themeAdd(payload) {
    return http.post('/api/cultural/resources/poc/theme/create', payload, false, { mode: 'json' })
  }

  themeEdit(payload) {
    return http.post('/api/cultural/resources/poc/theme/modify', payload, false, { mode: 'json' })
  }

  themeDelete(payload) {
    return http.post('/api/cultural/resources/poc/theme/delete', payload, false, { mode: 'json' })
  }

  getTheme(payload) {
    return http.post('/api/cultural/resources/poc/theme/info', payload, false, { mode: 'json' })
  }
}
