export const BASE_URL = process.env.VUE_APP_BASE_URL || 'https://api.ycwly.com'

export const user_type = 'culture'

export const areaLevel = ['province', 'city', 'county']

export const type = {
  image: '图片',
  video: '视频',
  audio: '音频',
}

export const typeName = {
  image: '图册',
  video: '视频',
  audio: '音频',
  post: '图文',
}

export const typeArr = [
  {
    label: '全部形式',
    value: '',
  },
  {
    label: '图文',
    value: 'post',
  },
  {
    label: '视频',
    value: 'video',
  },
  {
    label: '音频',
    value: 'audio ',
  },
  {
    label: '图册',
    value: 'image ',
  },
]
export const getGroupDefault = _type => [
  {
    name: '全部' + type[_type],
    id: 'all',
  },
  {
    name: '最近使用',
    id: 'last_time',
  },
  {
    name: '默认分组',
    id: 'default',
  },
]

export const sortOption = [
  {
    label: '上传时间(新-旧)',
    id: 'time_desc',
  },
  {
    label: '上传时间(旧-新)',
    id: 'time_asc',
  },
  {
    label: '文件名称(A-Z)',
    id: 'name_asc',
  },
  {
    label: '文件名称(Z-A)',
    id: 'name_desc',
  },
  {
    label: '文件大小(大-小)',
    id: 'size_desc',
  },
  {
    label: '文件大小(小-大)',
    id: 'size_asc',
  },
]

export const status = {
  0: '异常',
  1: '正常',
  2: '待转码',
  3: '转码中',
  4: '上传中',
}

export const exportStatus = [
  {
    id: 0,
    label: '全部状态',
  },
  {
    id: 1,
    label: '未发布',
  },
  {
    id: 2,
    label: '已发布',
  },
]

export const stepState = {
  0: 'init',
  1: 'base',
  2: 'relation',
  3: 'release',
}

export const timeOption = [
  {
    value: 0,
    label: '无时间',
  },
  {
    value: 1,
    label: '时间点',
  },
  {
    value: 2,
    label: '时间段',
  },
]

export const sourceData = [
  {
    value: '',
    label: '全部来源',
  },
  {
    value: 1,
    label: '平台自建',
  },
  {
    value: 2,
    label: '任务平台',
  },
  {
    value: 3,
    label: '媒体同步',
  },
]
