import http from '@/utils/http'

export class communityService {
  // 社区设置
  getSetting(payload) {
    return http.post('/api/app/community/manage/setting', payload, false, { mode: 'json' })
  }

  // 保存设置
  saveSetting(payload) {
    return http.post('/api/app/community/manage/setting/save', payload, false, { mode: 'json' })
  }

  // ---------------
  // 频道列表
  getChannelList(payload) {
    return http.post('/api/app/community/manage/channel/lists', payload, false, { mode: 'json' })
  }

  // 添加频道
  saveChannelAdd(payload) {
    return http.post('/api/app/community/manage/channel/create', payload, false, { mode: 'json' })
  }

  // 修改频道
  saveChannelEdit(payload) {
    return http.post('/api/app/community/manage/channel/modify', payload, false, { mode: 'json' })
  }

  // 删除频道
  deleteChannel(payload) {
    return http.post('/api/app/community/manage/channel/delete', payload, false, { mode: 'json' })
  }

  // 排序
  sortChannel(payload) {
    return http.post('/api/app/community/manage/channel/sort', payload, false, { mode: 'json' })
  }

  // 轮播状态
  swiperSetChannel(payload) {
    return http.post('/api/app/community/manage/channel/swiper/status', payload, false, { mode: 'json' })
  }

  // 轮播列表
  getChannelSwiper(payload) {
    return http.post('/api/app/community/manage/channel/swiper/lists', payload, false, { mode: 'json' })
  }

  // 轮播状态
  swiperDeleteChannel(payload) {
    return http.post('/api/app/community/manage/channel/swiper/delete', payload, false, { mode: 'json' })
  }

  // 添加轮播
  addSwiperChannel(payload) {
    return http.post('/api/app/community/manage/channel/swiper/create', payload, false, { mode: 'json' })
  }

  // 修改轮播
  editSwiperChannel(payload) {
    return http.post('/api/app/community/manage/channel/swiper/modify', payload, false, { mode: 'json' })
  }

  // ---------------

  // 贴子
  getPostList(payload) {
    return http.post('/api/app/community/manage/post/lists', payload, false, { mode: 'json' })
  }

  // 帖子详情
  getPostInfo(payload) {
    return http.post('/api/app/community/manage/post/info', payload, false, { mode: 'json' })
  }

  // 审核
  examinePost(payload) {
    return http.post('/api/app/community/manage/post/examine', payload, false, { mode: 'json' })
  }

  // 删除
  deletePost(payload) {
    return http.post('/api/app/community/manage/post/delete', payload, false, { mode: 'json' })
  }

  // ------------------
  // 回收站列表
  recycleList(payload) {
    return http.post('/api/app/community/manage/post/recycle/lists', payload, false, { mode: 'json' })
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/app/community/manage/post/recycle/recover', payload, false, { mode: 'json' })
  }

  // 回收站删除
  recycleDelete(payload) {
    return http.post('/api/app/community/manage/post/recycle/delete', payload, false, { mode: 'json' })
  }

  // 回收站清空
  recycleClear(payload) {
    return http.post('/api/app/community/manage/post/recycle/clean', payload, false, { mode: 'json' })
  }

  // 回收站总数
  recycleTotal(payload) {
    return http.post('/api/app/community/manage/post/recycle/total', payload, false, { mode: 'json' })
  }
}
