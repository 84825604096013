import { Cascader } from 'ant-design-vue'
import _ from 'lodash'

import DfValidationItem from './BjValidationItem'
import { getContainer } from './utils'

export default {
  name: 'BjCascader',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    ...DfValidationItem.props,
    ...Cascader.props,
    validationMode: {
      type: [String, Function],
      default: 'aggressive',
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    fieldNames: {
      type: Object,
      default: () => ({
        value: 'value',
        label: 'label',
        // disabled: 'disabled',
        children: 'children',
      }),
    },
    innerSearch: {
      type: Boolean,
      default: true,
    },
    showDisabled: {
      type: Boolean,
      default: true,
    },
    isString: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: () => [],
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: [],
      select: false,
    }
  },
  computed: {
    ownFieldNames() {
      const fieldNames = {
        value: 'value',
        label: 'label',
        disabled: 'disabled',
        children: 'children',
      }
      return { ...fieldNames, ...this.fieldNames }
    },
    optionList() {
      return this.options
      // return this.showDisabled ? this.showDisabledTransform(this.options) : this.delDisabledTransform(this.options)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        if (this.isString && typeof newValue === 'string') {
          if (newValue) {
            this.innerValue = newValue.split(',')
          } else {
            this.innerValue = []
          }
        } else {
          this.innerValue = _.clone(newValue)
        }
      },
    },
  },
  methods: {
    showDisabledTransform(list) {
      return list.map(e => {
        let cache = {
          ...e,
          value: e[this.ownFieldNames.value],
          label: e[this.ownFieldNames.label],
        }
        // if (this.ownFieldNames.disabled) {
        //   cache.disabled = e[this.ownFieldNames.disabled] ? false : true
        // }
        if (e[this.ownFieldNames.children] && e[this.ownFieldNames.children].length) {
          cache.children = this.showDisabledTransform(e[this.ownFieldNames.children])
        }
        return cache
      })
    },
    delDisabledTransform(list) {
      return list.reduce((accr, cur) => {
        if (cur[this.ownFieldNames.disabled]) {
          let cache = {
            value: cur[this.ownFieldNames.value],
            label: cur[this.ownFieldNames.label],
          }
          if (cur[this.ownFieldNames.children] && cur[this.ownFieldNames.children].length) {
            const cacheChildren = this.delDisabledTransform(cur[this.ownFieldNames.children])
            if (cacheChildren.length) {
              cache.children = cacheChildren
            }
          }
          return [...accr, cache]
        }
        return accr
      }, [])
    },
    blur() {
      this.$refs.cascader.blur()
    },
    focus() {
      this.$refs.cascader.focus()
    },
    onFilter(inputValue, path) {
      inputValue = inputValue.toLowerCase()
      return path.some(
        ({ label, value }) =>
          (label && label.toLowerCase().includes(inputValue)) || (value && value.toLowerCase().includes(inputValue))
      )
    },
    onChange(val, option) {
      if (this.isString) {
        this.$emit('change', val.join(','))
      } else {
        this.$emit('change', val, option)
      }
    },
    xjq(e) {
      this.select = e
    },
  },
  render() {
    const showSearch = this.innerSearch ? { filter: this.onFilter } : false
    const validationItemProps = Object.keys(DfValidationItem.props).reduce((accr, cur) => {
      if (typeof this.$props[cur] !== 'undefined') {
        return { ...accr, [cur]: this.$props[cur] }
      }
      return accr
    }, {})
    const cascaderProps = Object.keys(Cascader.props).reduce((accr, cur) => {
      if (typeof this.$props[cur] !== 'undefined' && cur !== 'fieldNames') {
        return { ...accr, [cur]: this.$props[cur] }
      }
      return accr
    }, {})
    return (
      // <DfValidationItem
      //   {...{
      //     props: { ...validationItemProps, mode: this.validationMode },
      //   }}
      // >
      <Cascader
        ref='cascader'
        style='width: 100%'
        {...{
          props: {
            ...cascaderProps,
            showSearch,
            fieldNames: this.fieldNames,
            options: this.options,
            value: this.innerValue,
            allowClear: this.allowClear,
            getPopupContainer: getContainer,
          },
          on: { ...this.$listeners, change: this.onChange, popupVisibleChange: this.xjq },
        }}
      >
        {Object.keys(this.$slots).map(name => (
          <template slot={name}>{this.$slots[name]}</template>
        ))}
        {this.select ? (
          <i slot='suffixIcon' class='ri-arrow-down-s-line suffix suffix-trans' />
        ) : (
          <i slot='suffixIcon' class='ri-arrow-down-s-line suffix' />
        )}
      </Cascader>
      // </DfValidationItem>
    )
  },
}
