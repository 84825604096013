import http from '@/utils/http'

export class loginService {
  /**
   * 登陆
   */
  login(payload) {
    return http.post('/api/passport/login', { ...payload, user_type: 'culture' })
  }

  // 获取验证码
  getValidCode(payload) {
    return http.post(
      '/api/message_center/valid/code',
      {
        user_type: 'culture',
        template: 'code',
        is_valid_register: '1',
        ...payload,
      },
      false,
      { mode: 'json' }
    )
  }

  // 注册
  register(payload) {
    return http.post('/api/passport/culture/register', payload)
  }

  // 微信登录
  loginWx(payload) {
    return http.post('/api/third_party/auth/login', payload)
  }

  // 检查微信登录撞他
  checkWx(payload) {
    return http.post('/api/third_party/auth/login/check', payload)
  }

  // 设置新密码
  forget(payload) {
    return http.post('/api/passport/culture/forget/password', payload)
  }

  // 验证忘记密码的验证码
  forgetCheck(payload) {
    return http.post('/api/passport/culture/forget/password/check', payload)
  }
}
