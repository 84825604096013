import http from '@/utils/http'

export class noticeService {
  // 列表
  getList(payload) {
    return http.post('/api/app/notice/manage/notice/lists', payload, false, { mode: 'json' })
  }

  // 回收站数量
  recycleTotal(payload) {
    return http.post('/api/app/notice/manage/notice/recycle/total', payload, false, { mode: 'json' })
  }

  //添加
  save(payload) {
    return http.post('/api/app/notice/manage/notice/create', payload, false, { mode: 'json' })
  }

  //编辑
  edit(payload) {
    return http.post('/api/app/notice/manage/notice/modify', payload, false, { mode: 'json' })
  }

  // 获取信息
  getInfo(payload) {
    return http.post('/api/app/notice/manage/notice/info', payload, false, { mode: 'json' })
  }

  // 回收站
  delete(payload) {
    return http.post('/api/app/notice/manage/notice/delete', payload, false, { mode: 'json' })
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/app/notice/manage/notice/recycle/lists', payload, false, { mode: 'json' })
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/app/notice/manage/notice/recycle/recover', payload, false, { mode: 'json' })
  }

  // 清空回收站
  recycleClear(payload) {
    return http.post('/api/app/notice/manage/notice/recycle/clean', payload, false, { mode: 'json' })
  }

  // 回收站彻底删除
  recycleDelete(payload) {
    return http.post('/api/app/notice/manage/notice/recycle/delete', payload, false, { mode: 'json' })
  }
}
