import Vue from 'vue'
import BjPreview from './BjPreview.vue'

const preview = {
  install: function (Vue) {
    const previewConstructor = Vue.extend(BjPreview)
    const instance = new previewConstructor()
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    Vue.prototype.BjPreview = (data, current) => {
      instance.data = data
      instance.current = current
      instance.visible = true
      document.body.style.overflow = 'hidden'
    }
  },
}

export default preview
