import http from '@/utils/http'

export class homeService {
  // 首页统计
  getStatistics(payload) {
    return http.post('/api/statistics/home/overview', payload, false, { mode: 'json' })
  }

  // 地区
  getRegion(payload) {
    return http.post('/api/statistics/home/region', payload, false, { mode: 'json' })
  }

  // 浏览排行
  getRank(payload) {
    return http.post('/api/statistics/home/rank/view', payload, false, { mode: 'json' })
  }

  // 专属顾问
  getAdviser(payload) {
    return http.post('/api/passport/culture/cloud/adviser', payload, false, { mode: 'json' })
  }
}
