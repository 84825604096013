<script>
import { Modal } from 'ant-design-vue'

export default {
  name: 'BjModal',
  props: {
    ...Modal.props,
  },
  render() {
    return (
      <Modal on={this.$listeners} {...{ props: { ...this.$props, destroyOnClose: true } }}>
        {Object.keys(this.$slots).map(name => (
          <template slot={name}>{this.$slots[name]}</template>
        ))}
        <i slot='closeIcon' class='ri-close-line icon' />
      </Modal>
    )
  },
}
</script>
<style lang="less">
.icon {
  color: #000;
  font-weight: normal;
  font-size: 24px;
}
</style>
