import http from '@/utils/http'

export class pointService {
  // 获取列表
  list(payload) {
    return http.post('/api/cultural/resources/poc/lists', payload)
  }

  //获取文化点类型
  getType(payload) {
    return http.post('/api/cultural/resources/poc/type', payload)
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/cultural/resources/poc/recycle/lists', payload)
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/cultural/resources/poc/recycle/recover', payload)
  }

  // 回收站删除
  recycleDelete(payload) {
    return http.post('/api/cultural/resources/poc/recycle/delete', payload)
  }

  // 回收站清空
  recycleClear(payload) {
    return http.post('/api/cultural/resources/poc/recycle/clean', payload)
  }

  // 回收站总数
  recycleTotal(payload) {
    return http.post('/api/cultural/resources/poc/recycle/total', payload)
  }

  // 检查文化点名称是否重名
  checkName(payload) {
    return http.post('/api/cultural/resources/poc/check/name', payload)
  }

  // 创建/修改文化点
  pointSave(payload) {
    return http.post('/api/cultural/resources/poc/save', payload)
  }

  // 获取文化点信息分布
  pointInfo(payload) {
    return http.post('/api/cultural/resources/poc/info', payload)
  }

  // 获取文化点信息分布
  pointStatus(payload) {
    return http.post('/api/cultural/resources/poc/status', payload)
  }

  // 删除
  pointDelete(payload) {
    return http.post('/api/cultural/resources/poc/delete', payload)
  }

  // 获取作者列表
  getAuthor(payload) {
    return http.post('/api/passport/culture/author/lists', payload)
  }

  // 复制
  pointCopy(payload) {
    return http.post('/api/cultural/resources/poc/copy', payload)
  }

  // 移动
  pointMove(payload) {
    return http.post('/api/cultural/resources/poc/move', payload)
  }
}
