import http from '@/utils/http'

export class appLinkService {
  // app 弹窗第一级
  appList(payload) {
    return http.post('/api/app_center/link/app/lists', payload, false, { mode: 'json' })
  }

  // app 弹窗第二级
  appChirlList(payload) {
    return http.post('/api/app_center/link/app/page_type/lists', payload, false, { mode: 'json' })
  }

  // 数据列表
  getList(payload) {
    return http.post('/api/app_center/link/app/data/lists', payload, false, { mode: 'json' })
  }

  // 获取文化点的模块页面的列表数据
  getPocList(payload) {
    return http.post('/api/cultural/link/poc/page/lists', payload, false, { mode: 'json' })
  }

  // 获取文化内容的模块页面数据
  getContentList(payload) {
    return http.post('/api/cultural/link/content/page/lists', payload, false, { mode: 'json' })
  }
}
