import http from '@/utils/http'

export class newsGroupService {
  // 获取分组列表
  list(payload) {
    return http.post('/api/app/news/manage/news/group/lists', payload, false, { mode: 'json' })
  }

  // 删除分组
  delete(payload) {
    return http.post('/api/app/news/manage/news/group/delete', payload, false, { mode: 'json' })
  }

  // 编辑分组
  edit(payload) {
    return http.post('/api/app/news/manage/news/group/modify', payload, false, { mode: 'json' })
  }

  // 添加分组
  add(payload) {
    return http.post('/api/app/news/manage/news/group/create', payload, false, { mode: 'json' })
  }

  // 移动保存
  sort(payload) {
    return http.post('/api/app/news/manage/news/group/sort', payload, false, { mode: 'json' })
  }
}
