import Vue from 'vue'

import LoadingBar from './LoadingBar.vue'

LoadingBar.newInstance = properties => {
  const props = properties || {}

  // eslint-disable-next-line vue/require-name-property
  const instance = new Vue({
    data() {
      return props
    },
    render(h) {
      return h(LoadingBar, { props })
    },
  })
  const component = instance.$mount()
  document.body.appendChild(component.$el)

  return {
    update(options) {
      if ('percent' in options) {
        instance.percent = options.percent
      }
      if (options.status) {
        instance.status = options.status
      }
      if ('show' in options) {
        instance.show = options.show
      }
    },
    component: instance,
    destroy() {
      document.body.removeChild(component.$el)
    },
  }
}

export default LoadingBar
