import http from '@/utils/http'

export class appVenueService {
  // 场馆列表
  getList(payload) {
    return http.post('/api/app/venue/manage/venue/list', payload, false, { mode: 'json' })
  }

  // 可用场馆列表
  getAvailableList(payload) {
    return http.post('/api/app/venue/manage/venue/available/list', payload, false, { mode: 'json' })
  }

  // 分组列表
  getGroup(payload) {
    return http.post('/api/app/venue/manage/venue/group/list', payload, false, { mode: 'json' })
  }

  // 添加
  add(payload) {
    return http.post('/api/app/venue/manage/venue/create', payload, false, { mode: 'json' })
  }

  // 编辑
  edit(payload) {
    return http.post('/api/app/venue/manage/venue/update', payload, false, { mode: 'json' })
  }

  // 删除
  delete(payload) {
    return http.post('/api/app/venue/manage/venue/delete', payload, false, { mode: 'json' })
  }

  // 获取详情
  getDetail(payload) {
    return http.post('/api/app/venue/manage/venue/detail', payload, false, { mode: 'json' })
  }

  // 预订列表
  getListSkus(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/skus', payload, false, { mode: 'json' })
  }

  // 预订info
  getInfoService(payload) {
    return http.post('/api/app/venue/manage/settings/info', payload, false, { mode: 'json' })
  }

  // 保存自定义服务
  saveService(payload) {
    return http.post('/api/app/venue/manage/settings/service/save', payload, false, { mode: 'json' })
  }

  // 删除自定义服务
  deleteService(payload) {
    return http.post('/api/app/venue/manage/settings/service/delete', payload, false, { mode: 'json' })
  }

  // 排序
  sort(payload) {
    return http.post('/api/app/venue/manage/settings/service/sort', payload, false, { mode: 'json' })
  }

  // 场馆预订列表
  getAppList(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/list', payload, false, { mode: 'json' })
  }

  // 场馆预订列表删除
  deleteAppList(payload) {
    return http.post('/api/app/venue/manage/settings/appointment/delete', payload, false, { mode: 'json' })
  }

  // 保存周边
  saveAround(payload) {
    return http.post('/api/app/venue/manage/settings/around/save', payload, false, { mode: 'json' })
  }

  // 获取周边
  getAround(payload) {
    return http.post('/api/app/venue/manage/settings/around/info', payload, false, { mode: 'json' })
  }

  // 保存自定义
  saveCustom(payload) {
    return http.post('/api/app/venue/manage/settings/service/save', payload, false, { mode: 'json' })
  }

  // 保存场馆预定
  saveName(payload) {
    return http.post('/api/app/venue/manage/settings/save', payload, false, { mode: 'json' })
  }

  // 预定列表
  getManageAppList(payload) {
    return http.post('/api/app/venue/manage/appointment/list', payload, false, { mode: 'json' })
  }

  recycleList(payload) {
    return http.post('/api/app/venue/manage/venue/recycle/list', payload, false, { mode: 'json' })
  }

  // 回收站总数
  getRecycle(payload) {
    return http.post('/api/app/venue/manage/venue/recycle/total', payload, false, { mode: 'json' })
  }

  // 恢复
  recycleRecover(payload) {
    return http.post('/api/app/venue/manage/venue/recycle/recover', payload, false, { mode: 'json' })
  }

  recycleDelete(payload) {
    return http.post('/api/app/venue/manage/venue/recycle/delete', payload, false, { mode: 'json' })
  }

  // 清空
  recycleClear(payload) {
    return http.post('/api/app/venue/manage/venue/recycle/clean', payload, false, { mode: 'json' })
  }

  // 场馆预订记录详情
  getAppointmentDetail(payload) {
    return http.post('/api/app/venue/manage/appointment/detail', payload, false, { mode: 'json' })
  }

  // 核销
  write_off(payload) {
    return http.post('/api/app/venue/manage/appointment/write_off', payload, false, { mode: 'json' })
  }

  // 删除预定
  deleteAppointment(payload) {
    return http.post('/api/app/venue/manage/appointment/delete', payload, false, { mode: 'json' })
  }

  // 退订
  cancel(payload) {
    return http.post('/api/app/venue/manage/appointment/cancel', payload, false, { mode: 'json' })
  }
}
