import http from '@/utils/http'

export class newsService {
  // 列表
  getList(payload) {
    return http.post('/api/app/news/manage/news/lists', payload, false, { mode: 'json' })
  }

  // 获取分组列表
  groupList(payload) {
    return http.post('/api/app/news/manage/news/group/lists', payload, false, { mode: 'json' })
  }

  // 回收站数量
  recycleTotal(payload) {
    return http.post('/api/app/news/manage/news/recycle/total', payload, false, { mode: 'json' })
  }

  //添加
  save(payload) {
    return http.post('/api/app/news/manage/news/create', payload, false, { mode: 'json' })
  }

  //编辑
  edit(payload) {
    return http.post('/api/app/news/manage/news/modify', payload, false, { mode: 'json' })
  }

  // 获取信息
  getInfo(payload) {
    return http.post('/api/app/news/manage/news/info', payload, false, { mode: 'json' })
  }

  // 回收站
  delete(payload) {
    return http.post('/api/app/news/manage/news/delete', payload, false, { mode: 'json' })
  }

  // 回收站列表
  recycleList(payload) {
    return http.post('/api/app/news/manage/news/recycle/lists', payload, false, { mode: 'json' })
  }

  // 回收站恢复
  recycleRecover(payload) {
    return http.post('/api/app/news/manage/news/recycle/recover', payload, false, { mode: 'json' })
  }

  // 清空回收站
  recycleClear(payload) {
    return http.post('/api/app/news/manage/news/recycle/clean', payload, false, { mode: 'json' })
  }

  // 回收站彻底删除
  recycleDelete(payload) {
    return http.post('/api/app/news/manage/news/recycle/delete', payload, false, { mode: 'json' })
  }
}
